<template>
  <vs-card class="campaigns">
    <b-alert @dismissed="dismissCountDown=0" :show="dismissCountDown" @dismiss-count-down="countDownChanged" :variant="alertType" class="alert-fixed" dismissible>
      {{ alertError }}
    </b-alert>
    <div id="panel">
      <div class="column">
        <div class="field">
          <label class="short" for="period">Period</label>
          <date-range-picker v-model="perfRange" :options="perfRangeOptions" :key="timezone" />
        </div>
        <div class="field">
          <label class="short" for="created_at">Created</label>
          <date-range-picker format="DD/MM/YYYY HH:mm" v-model="createdAtRange" :options="createdAtRangeOptions" />
        </div>
        <div class="field">
          <label class="short" for="status">Status</label>
          <select v-model="campaignStatus">
            <option :value="status" :key="status" v-for="status in campaignStatuses">
              {{ status }}
            </option>
          </select>
        </div>
      </div>
      <div class="column">
        <h3>Time Zone</h3>
        <div class="time-zone-buttons">
          <div @click="changeTimezone(timeObject.value)" v-for="timeObject in availableTimezones" :key="timeObject.label" class="toggle-button" :class="`${timezone == timeObject.value ? 'active': ''}`">{{ timeObject.label }}</div>
        </div>
      </div>
      <div class="column source">
        <h3>Source</h3>
        <div class="source-buttons">
          <div>
            <div @click="updatePlatformFilter('all')" class="toggle-button" :class="`${allPlatformsSelected ? 'active' : ''}`">All</div>
            <div @click="updatePlatformFilter('yahoo')" class="toggle-button" :style="`background-color: ${isPlatformSelected('yahoo') ? getPlatformColor('yahoo') : '#ffffff'}`">
              <img v-show="isPlatformSelected('yahoo')" src="@/assets/images/icons/icon_white_yahoo.svg"/>
              <img v-show="!isPlatformSelected('yahoo')" src="@/assets/images/icons/icon_colored_yahoo.svg"/>
            </div>
          </div>
          <div>
            <div @click="updatePlatformFilter('facebook')" class="toggle-button" :style="`background-color: ${isPlatformSelected('facebook') ? getPlatformColor('facebook') : '#ffffff'}`">
              <img v-show="isPlatformSelected('facebook')" src="@/assets/images/icons/icon_white_facebook.svg"/>
              <img v-show="!isPlatformSelected('facebook')" src="@/assets/images/icons/icon_colored_facebook.svg"/>
            </div>
            <div @click="updatePlatformFilter('taboola')" class="toggle-button" :style="`background-color: ${isPlatformSelected('taboola') ? getPlatformColor('taboola') : '#ffffff'}`">
              <img v-show="isPlatformSelected('taboola')" src="@/assets/images/icons/icon_white_taboola.svg"/>
              <img v-show="!isPlatformSelected('taboola')" src="@/assets/images/icons/icon_colored_taboola.svg"/>
            </div>
          </div>
          <div>
            <div @click="updatePlatformFilter('twitter')" class="toggle-button" :style="`background-color: ${isPlatformSelected('twitter') ? getPlatformColor('twitter') : '#ffffff'}`">
              <img v-show="isPlatformSelected('twitter')" src="@/assets/images/icons/icon_white_twitter.svg"/>
              <img v-show="!isPlatformSelected('twitter')" src="@/assets/images/icons/icon_colored_twitter.svg"/>
            </div>
            <div @click="updatePlatformFilter('pinterest')" class="toggle-button" :style="`background-color: ${isPlatformSelected('pinterest') ? getPlatformColor('pinterest') : '#ffffff'}`">
              <img v-show="isPlatformSelected('pinterest')" src="@/assets/images/icons/icon_white_pinterest.svg"/>
              <img v-show="!isPlatformSelected('pinterest')" src="@/assets/images/icons/icon_colored_pinterest.svg"/>
            </div>
          </div>
          <div>
            <div @click="updatePlatformFilter('tiktok')" class="toggle-button" :style="`background-color: ${isPlatformSelected('tiktok') ? getPlatformColor('tiktok') : '#ffffff'}`">
              <img v-show="isPlatformSelected('tiktok')" src="@/assets/images/icons/icon_white_tiktok.svg"/>
              <img v-show="!isPlatformSelected('tiktok')" src="@/assets/images/icons/icon_colored_tiktok.svg"/>
            </div>
            <div @click="updatePlatformFilter('snapchat')" class="toggle-button" :style="`background-color: ${isPlatformSelected('snapchat') ? getPlatformColor('snapchat') : '#ffffff'}`">
              <img v-show="isPlatformSelected('snapchat')" src="@/assets/images/icons/icon_white_snapchat.svg"/>
              <img v-show="!isPlatformSelected('snapchat')" src="@/assets/images/icons/icon_colored_snapchat.svg"/>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="medium" for="assignee">Assignee</label>
          <multiselect
            class             = "input-field"
            :custom-label     = "assigneesFullName"
            v-model           = "filter_assignee"
            track-by          = "id"
            :options          = "assignees"
            :multiple         = "true"
            placeholder       = "All"
            selectLabel       = ""
            deselectLabel     = ""
            :close-on-select  = "false"
            :clear-on-select  = "false"
            :preserve-search  = "true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} assignees selected</span>
            </template>
          </multiselect> 
        </div>
        <div class="field">
          <label class="medium" for="publisher">Publisher</label>
          <multiselect
            class="input-field"
            label             = "name"
            v-model           = "publisher_id"
            track-by          = "id"
            :options          = "publishers"
            :multiple         = "true"
            placeholder       = "All"
            selectLabel       = ""
            deselectLabel     = ""
            :close-on-select  = "false"
            :clear-on-select  = "false"
            :preserve-search  = "true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} publishers selected</span>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="column">
        <div class="field text">
          <input v-on:keyup.enter="refreshTextField" type="text" placeholder="Campaign Name" autocomplete="off" v-model="displayName" />
        </div>
        <div class="field text">
          <input v-on:keyup.enter="refreshTextField" type="text" placeholder="Campaign ID" autocomplete="off" v-model="filter_campaign_id" />
        </div>
        <div class="field text">
          <input v-on:keyup.enter="refreshTextField" type="text" placeholder="Article ID" autocomplete="off" v-model="filter_utm_medium" />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="long" for="adAccount">Ad Account</label>
          <multiselect
            class             = "input-field long small"
            label             = "name"
            v-model           = "filter_ad_account"
            track-by          = "id"
            :options          = "computedAdAccounts"
            :multiple         = "true"
            placeholder       = "All"
            selectLabel       = ""
            deselectLabel     = ""
            :close-on-select  = "false"
            :clear-on-select  = "false"
            :preserve-search  = "true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} accounts selected</span>
            </template>
          </multiselect>
        </div>
        <div class="field">
          <label class="long" for="vp">Video Player</label>
          <multiselect
            class             = "input-field long"
            label             = "text"
            v-model           = "filter_vp"
            track-by          = "value"
            :options          = "videoPlayerOptions"
            :multiple         = "true"
            placeholder       = "All"
            selectLabel       = ""
            deselectLabel     = ""
            :close-on-select  = "false"
            :clear-on-select  = "false"
            :preserve-search  = "true"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} players selected</span>
            </template>
          </multiselect>
        </div>
        <div class="field">
          <label class="long" for="status">Bid Strategy</label>
          <select v-model="bidStrategy">
            <option :value="strategy" :key="strategy" v-for="(strategy, friendlyName) in bidStrategies">
              {{ friendlyName }}
            </option>
          </select>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <input v-on:keyup.enter="refreshTextField" id="utmCampaign" type="text" placeholder="UTM Campaign" autocomplete="off" v-model="campaignName" />
        </div>
        <div class="field">
          <label class="longer" for="last_changed">Last changed before</label>
          <date-range-picker format="DD/MM/YYYY HH:mm" v-model="lastChangedRange" :options="lastChangedRangeOptions" id="lastChangeBefore" />
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-block">
            <div class="checkbox">
              <input class="form-check-input" type="checkbox" v-model="showNew" />
              <label class="form-check-label" for="inlineCheckbox1">Show New</label>
            </div>
            <div class="checkbox">
              <input class="form-check-input" type="checkbox" v-model="bookmarked" />
              <label class="form-check-label" for="inlineCheckbox1">Show Bookmarked</label>
            </div>
          </div>
          <div>
            <!-- <div @click="getReport()" :disabled="isDownloadingReport" id="export-button">Export
              <img src="@/assets/images/icons/icon_white_export.svg"/>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="panel-controller" class="align-items-left">
      <b-link v-b-toggle="'adv-filtering'"><i class="far fa-plus"></i>Advanced Filtering</b-link>
      <div class="d-flex flex-wrap">
        <div class="form-check form-check-inline">
          <template v-if="liveUpdatesEnabled">
            <i aria-hidden="true" class="fa fa-circle text-danger blink"></i>&nbsp; LIVE
          </template>
          <template v-else>
            <input :disabled="disableAutoRefresh" class="form-check-input" type="checkbox" v-model="autoRefresh" id="autoRefresh" />
            <label class="form-check-label" for="inlineCheckbox3">Auto Refresh</label>
          </template>
        </div>
        <div @click="refresh(true)" class="button green">Apply Filter</div>
        <div @click="clearFilters()" class="button red">Clear Filters</div>
        <div @click="refresh(false, true)" class="button red"><i class="fas fa-sync" :class="isRefreshing ? 'fa-spin' : ''"></i></div>
      </div>
      <b-collapse id="adv-filtering">
        <b-card class="my-3">
          <div class="d-flex">
            <div class="input">
              <label for="device" >Device</label>
              <multiselect
                class             = "input-field"
                label             = "text"
                v-model           = "filter_device"
                track-by          = "value"
                :options          = "devices"
                :multiple         = "true"
                placeholder       = "Select Devices"
                selectLabel       = ""
                deselectLabel     = ""
                :close-on-select  = "false"
                :clear-on-select  = "false"
                :preserve-search  = "true"
              >
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} devices selected</span>
                </template>
              </multiselect> 
            </div>
            <div class="input">
              <label for="author">Author</label>
              <multiselect
                class             = "input-field"
                :custom-label     = "assigneesFullName"
                v-model           = "filter_author"
                track-by          = "id"
                :options          = "assignees"
                :multiple         = "true"
                placeholder       = "All"
                selectLabel       = ""
                deselectLabel     = ""
                :close-on-select  = "false"
                :clear-on-select  = "false"
                :preserve-search  = "true"
              >
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} authors selected</span>
                </template>
              </multiselect> 
            </div>
            <div class="col">
              <b-button class="button blue" variant="outline-primary" @click="addAdvFilter">+</b-button>
              <b-button class="button red" variant="outline-danger" @click="clearAdvFilter">Clear</b-button>
              <b-button class="button green" variant="outline-primary" @click="refresh(true)">Apply</b-button>
            </div>
          </div>
          <div class="form-inline" :key="key" v-for="(metric, key) in adv_filters_metrics">
            <div class="form-group">
              <select class="form-control" v-model="metric.metric">
                <option value="units">Items</option>
                <option value="visits">Sessions</option>
                <option value="clicks">Clicks</option>
                <option value="user_value">User Value</option>
                <option value="impressions">Ad Impressions</option>
                <option value="cpc">CPC</option>
                <option value="ctr">CTR</option>
                <option value="rpm">RPM</option>
                <option value="revenue">Revenue</option>
                <option value="spend">Spend</option>
                <option value="profit">Profit</option>
                <option value="roi">ROI</option>
                <option value="margin">Margin</option>
              </select>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="metric.rel">
                <option value=">" selected>&gt;</option>
                <option value=">=">&gt;=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value="==">=</option>
              </select>
            </div>
            <div class="form-group">
              <input class="form-control" v-model="metric.value" />
            </div>
            <div class="form-group">
              <b-button class="button red" variant="outline-danger" @click="removeAdvFilter(metric)"><i class="fas fa-times"></i></b-button>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <div v-if="showRefreshWarning" class="alert alert-warning">
      <i aria-hidden="true" class="fa fa-exclamation-circle"></i>
      There are new updates for the selected filters, refresh to get the latest data
    </div>
    <div class="">
      <div class="campaigns-container">
        <b-collapse :visible="multiEdit.isOpen" class="mt-2">
          <MultiEdit :selectedCampaigns="multiEdit.selectedIds.map(id => campaigns.find(campaign => campaign.id === id))" :selectedIds="multiEdit.selectedIds" :assignees="assignees" :onBulkStatusChange="onBulkStatusChange" :onBudgetChange="onBudgetChange" :onMaxBidChange="onMaxBidChange"></MultiEdit>
        </b-collapse>
        <table v-if="tableReloaded" id="campaigns" class="tablemobile text-center table-bordered table">
          <thead class="sticky">
            <tr v-if="Object.keys(totals).length > 0">
              <th :colspan="multiEdit.isOpen ? 8 : 7">
                <b-button @click="multiEdit.isOpen = !multiEdit.isOpen" variant="primary" size="sm" class="float-left">{{`${multiEdit.isOpen ? 'Close' : ''} Multi Edit`}}</b-button>
              </th>
              <template v-for="col in selectedColumnsData">
                <th :key="col.name">
                  <template v-if="col.name === 'rpm'">
                    <b-button @click="loadRpms('all')" :id="`popover-rpm-total`" variant="primary" class="revenu-btn">{{ col.formatTotals(totals) }}</b-button>
                    <b-popover :target="`popover-rpm-total`" triggers="hover focus" placement="leftbottom" delay="500" offset="500" custom-class="popover-custom-body">
                      <h6>RPM</h6>
                      <table>
                        <tbody>
                        <tr :key="key + 'total'" v-for="(value, key) in rpms">
                          <td>{{ key }}</td>
                          <td>{{ numeral(value).format('$0,0.000') }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </b-popover>
                  </template>

                    <template v-else-if="col.name === 'revenue'">
                      <b-button @click="loadRevenues('all')" :id="`popover-rev-total`" variant="primary" class="revenu-btn"> {{ col.formatTotals(totals) }} </b-button>
                      <b-popover :target="`popover-rev-total`" triggers="hover focus" placement="leftbottom" delay="500" offset="500" custom-class="popover-custom-body">
                        <h6>Revenue</h6>
                        <table>
                          <tbody>
                          <tr :key="key + 'total'" v-for="(value, key) in revenues">
                            <td>{{ key }}</td>
                            <td>{{ numeral(value).format('$0,0.000') }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </b-popover>
                    </template>

                    <div v-else class="resize-cell">
                      <span :class="col.cssClass ? col.cssClass(totals) : ''">{{ col.formatTotals(totals) }}</span>
                    </div>
                </th>
              </template>
            </tr>
            <tr>
              <th class="th th2" v-if="multiEdit.isOpen">
                <input type="checkbox" v-model="allSelected">
                Select All
              </th>
              <th class="th"></th>
              <th class="th"></th>
              <th class="th text-center">Site</th>
              <th class="th"></th>
              <th class="th thTop th2 text-left">Campaign</th>
              <th class="th">Bid <i v-if="sort_by == 'bid'" :class="'fas fa-sort-' + (sort_dir == 'desc' ? 'down' : 'up')"></i></th>
              <th class="th">Budget <i v-if="sort_by == 'daily_budget'" :class="'fas fa-sort-' + (sort_dir == 'desc' ? 'down' : 'up')"></i></th>

              <template v-for="col in selectedColumnsData">
                <th :key="col.name" :class="`th${col.isMetricsAgg ? ' sorterColumn' : ''}`" @click="changeSort(col.name)">{{ col.header }} <i v-if="col.isMetricsAgg && sort_by == col.name" :class="'fas fa-sort-' + (sort_dir == 'desc' ? 'down' : 'up')"></i></th>
              </template>
            </tr>
          </thead>
          <template v-if="!campaigns_data && !campaigns_error">
            <tbody>
            <tr>
              <td colspan="29">
                <clip-loader color="#20313b" />
              </td>
            </tr>
            </tbody>
          </template>
          <template v-if="campaigns_error">
            <tbody>
            <tr>
              <td colspan="20" style="color: red;">
                {{ campaigns_error }}
              </td>
            </tr>
            </tbody>
          </template>
          <template v-else-if="campaigns_data && campaigns_data.length == 0">
            <tbody>
              <tr>
                <td colspan="29">No campaigns</td>
              </tr>
            </tbody>
          </template>
          <template v-else v-for="content in campaigns_data">
            <tbody :key="content.id" :ref="`campaign-${content.id}`">
              <tr :key="content.id" :class="colorPick(content)" @click="markCampaign(content)">
                <td v-if="multiEdit.isOpen">
                  <label><input type="checkbox" :value="content.id" v-model="multiEdit.selectedIds"></label>
                </td>
                <td>
                  <span :class="`campaign-status ${content.status}`" @click="openStatusChange([content], content.status === 'running' ? 'paused' : 'running')"><i :class="`fas ${statusIcons(content.status)}`"></i></span>
                </td>
                <td class="chart-td" @click="openPerfModal(content)">
                  <canvas v-if="tiny_perfs[content.id] && !isTinyPerfEmpty(content.id)" :id="`sparkline-${content.id}`" width="70" height="30"></canvas>
                  <span v-else class="btn badge badge-secondary">No Data</span>
                </td>
                <td class="text-center text-uppercase site-code" :style="`color: ${publisherObject(content).color}`" >{{ publisherObject(content).code }}</td>
                <td><i @click="bookmark(content)" :class="`${content.bookmarked ? 'fas' : 'far'} fa-star text-warning`"></i></td>
                <td class="th2 text-left" :class="colorPick(content)" @mouseover="toggleFullNameTooltip(content.id, $event)">
                  <div class="campaign-name" v-if="content">
                    <div>
                      <b-tooltip disabled :id="`${content.id}-tooltip-name`" :target="`${content.id}-name`" :title="(content.display_name || content.name || '').replace(/_/g, ' ')"></b-tooltip>
                      <div :id="`${content.id}-name`" class="campaign-name-title mr-2">
                        <div>
                          <div @click="toggleAdsets(content)" :class="`campaign-name-title-text ${showAdsets === content.id ? '' : 'ellipsis'}`">{{ isMobile ? (content.display_name || content.name || '').replace(/_/g, ' ') : (content.display_name || content.name || '')}}
                            <span v-if="notifications[content.id]" class="ml-2 badge badge-danger badge-pill">{{ notifications[content.id] }}</span>
                            <span v-if="content.has_note" class="ml-2 badge badge-warning badge-pill"><i class="far fa-sticky-note"></i></span>
                            <span v-if="content.is_in_learning_phase" class="ml-2 badge badge-info badge-pill"><i class="fas fa-balance-scale-right"></i></span>
                          </div>
                          <div class="hash-code">ID: {{ content.id }}. Created: {{ content.created_ago }} ago. <span :class="moment(new Date()).diff(content.user_changed_at, 'minutes') <= 30 ? 'text-bold' : ''">Last changed: {{ content.last_audit_ago ? `${content.last_audit_ago} ago` : 'never' }}</span>. Last Cost: {{ content.last_cost_ago ? `${content.last_cost_ago} ago` : 'never' }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="campaign-name-icons" style="display: flex; align-items: center">
                      <i v-if="content.complement" class="fal fa-snooze"></i>
                      <i v-if="content.start_time && content.status != 'running'" class="text-danger far fa-clock"></i>    
                      <span v-if="moment(new Date()).diff(content.created_at, 'hours') <= 72" class="text-light badge badge-primary mx-2">NEW</span>
                      <i v-b-tooltip.hover v-if="content.bid_strategy && content.bid_strategy === 'LOWEST_COST_WITH_BID_CAP'" title="Bid Cap" class="m-2 fas fa-hat-cowboy"></i>
                      <country-flag v-if="content.countries && isSingleCountry(content.countries)" :country='content.countries[0]' size='small'/>
                      <i v-else-if="content.countries" class="far fa-globe-americas"></i>
                      <i v-b-tooltip.hover v-if="content.devices && content.devices.length > 0" :title='getTitleForDevice(content.devices)' :class="`devices ${getClassForDevice(content.devices)}`"></i>
                      <i v-if="content.platform == 'push'" class="fal fa-bells"></i>
                      <img src="./images/taboola.png" class="taboola-icon" v-else-if="content.platform == 'taboola'"/>
                      <i v-else-if="content.platform == 'tiktok'" class="fas fa-music-alt"></i>
                      <i v-else :class="`fab fa-${content.platform}${content.platform == 'facebook' ? '-f' : (content.platform == 'snapchat' ? '-ghost' : '')}`"></i>
                      <b-dropdown id="dropdown-dropright" dropright text="Drop-Right" size="md" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template slot="button-content"><i class="fal fa-ellipsis-v"></i></template>
                        <b-dropdown-item :to="{ name: 'EditCampaign', params: { id: content.id } }">Edit</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'DuplicateCampaign', params: { id: content.id }}">Duplicate</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'MassGenerate', params: { id: content.id } }">Mass Generate</b-dropdown-item>
                        <b-dropdown-item target="_blank" :href="externalLink(content)">Open On Source</b-dropdown-item>
                        <b-dropdown-item target="_blank" :href="wpLink(content)">Edit On WP</b-dropdown-item>
                        <b-dropdown-item target="_blank" :href="content.article_url">Open URL</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="w-24">
                      <!-- Bid -->
                  </div>
                </td>
                <td>
                  <div v-if="((budgets[content.id] || {}).daily_budget !== undefined || (edits[content.id] || {}).isEditingDailyBudget) && (platformOptions(content.platform).daily_budget || {}).campaign" class=" budgets-cell input-group">
                    <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                    <input type="text" :disabled="!(edits[content.id] || {}).isEditingDailyBudget || content.status != 'running'" min="0.00" class="form-control" v-model="budgets[content.id].daily_budget" v-on:keyup.enter="saveCampaignDailyBudget(content)"/>
                    <i class="edit-btn fas fa-pencil text-secondary" v-if="budgets[content.id] && !(edits[content.id] || {}).isEditingDailyBudget && content.status == 'running'" @click="editCampaignDailyBudget(content)"></i>
                    <template v-else-if="edits[content.id] && edits[content.id].isEditingDailyBudget">
                      <i class="edit-btn fas fa-check text-secondary" @click="saveCampaignDailyBudget(content)"></i>
                      <i class="edit-btn fas fa-history text-secondary" @click="revertCampaignDailyBudget(content)"></i>
                    </template>
                  </div>
                  <div class="budget_type">
                      {{ (content.metadata || {}).budget_type == 'lifetime' ? 'lifetime' : 'daily' }}
                  </div>
                </td>
                <template v-for="col in selectedColumnsData">
                  <td :key="col.name" :class="col.cssClass ? col.cssClass(content) : ''">
                    <template v-if="col.name == 'units'">
                      <UnitsTooltip :campaign_id="content.id" :timezone="timezone" :start="selectedDate.start" :end="selectedDate.end" :content="col.format(content)" />
                    </template>

                    <template v-else-if="col.name == 'rpm'">
                      <b-button @click="loadRpms(content.id)" :id="`popover-rpm-${content.id}`" variant="primary" class="revenu-btn">{{ col.format(content) }}
                      </b-button>
                      <b-popover :target="`popover-rpm-${content.id}`" triggers="hover focus" custom-class="popover-custom-body">
                        <table>
                          <tbody>
                          <tr :key="key + content.id" v-for="(content, key) in rpms">
                            <td>{{ key }}</td>
                            <td>{{ numeral(content).format('$0,0.000') }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </b-popover>
                    </template>

                    <template v-else-if="col.name == 'revenue'">
                      <b-button @click="loadRevenues(content.id)" :id="`popover-${content.id}`" variant="primary" class="revenu-btn">{{ col.format(content) }}</b-button>
                      <b-popover :target="`popover-${content.id}`" triggers="hover focus" custom-class="popover-custom-body">
                        <table>
                          <tbody>
                          <tr :key="key + content.id" v-for="(content, key) in revenues">
                            <td>{{ key }}</td>
                            <td>{{ numeral(content).format('$0,0.000') }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </b-popover>
                    </template>

                    <template v-else-if="col.name == 'quality'">
                      {{ col.format(content) }}<span v-if="content.has_low_quality" class="ml-2 badge badge-danger badge-pill"><i class="fas fa-exclamation"></i></span>
                    </template>

                    <template v-else-if="col.name == 'negative_sentiment'">
                      <span :class="col.cssClass ? col.cssClass(content) : ''"><i class="fal fa-exclamation-circle" v-if="content.negative_sentiment > 10"></i> {{ col.format(content) }}</span>
                    </template>

                    <span v-else>{{ col.format(content) }}</span>
                    <template v-if="col.customElement">
                      <span :class="col.customElement.cssClass ? col.customElement.cssClass(content) : '' "> {{ col.customElement.format(content) }}</span>
                    </template>
                  </td>
                </template>
              </tr>
            </tbody>
            <AdsetRow
              :selectedColumnsData="selectedColumnsData"
              :selectedColumns="selectedColumns"
              :perfRangeOptions="perfRangeOptions"
              :timezone="timezone"
              :notifications="notifications[(currentCampaign || {}).id]"
              :refreshCampaignLastChanged="refreshCampaignLastChanged"
              :platform="content.platform"
              :alertErrorSetter="alertErrorSetter"
              :campaignIsRunning="content.status == 'running'"
              :key="content.revenue"
              :start="selectedDate.start"
              :end="selectedDate.end"
              :campaignId="content.id"
              :platformOptions="platformOptions(content.platform)"
              v-if="content.id == showAdsets"
            ></AdsetRow>
          </template>
        </table>
      </div>
    </div>
    <b-pagination pills v-model="currentPage" align="center" :total-rows="totalRows" :per-page="limit"></b-pagination>
    <div class="text-center">Campaigns per page: {{ this.limit }} - Total Campaigns: {{ totalRows }}</div>
    <PerfModal ref="perfModal" entityType="campaign" :entity="currentCampaign" :selectedDate="selectedDate" :perfRangeOptions="perfRangeOptions" :timezone="timezone" :notifications="notifications[(currentCampaign || {}).id]"/>
  </vs-card>
</template>

<script>
  import Multiselect from "vue-multiselect";
  import moment from 'moment-timezone';
  import numeral from 'numeral';
  import { CancelToken } from 'axios';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  import PerfModal from './perf/PerfModal';
  import CountryFlag from 'vue-country-flag';
  import { OPTIONS, VALIDATION, UTILS } from "../utils/campaign_form.js";
  import { ICONS, DEVICES_DISPLAY } from "../utils/overview.js";
  import { LOGGER, alog, ilog, elog } from '../utils/logger/logger.js'
  import { STORAGE, COLUMNS } from '../utils/settings/columns.js';
  import UnitsTooltip from '../components/overview/UnitsTooltip.vue';
  import AdsetRow from "../components/overview/AdsetRow.vue";
  import MultiEdit from "../components/campaign-form/MultiEdit.vue";
  import { AVAILABLE_TIMEZONES, VIDEO_PLAYER_OPTIONS, datePickerRanges } from "../utils/values.js";
  import { isSameCountry } from "../utils/helpers.js";
  import { PUBLISHERS } from "../utils/product_entities.js";
  import { COUNTRIES } from "../utils/countries.js";
  import platformOptions from "../utils/platform_options";

  export default {
    name: 'Campaigns',
    props: ['tableReloaded'],
    data: function() {
      return {
        defaultFilters: {
          filter_device: [],
          filter_vp: [],
          filter_platform: [],
          filter_utm_medium: '',
          filter_campaign_id: '',
          filter_assignee: [],
          filter_author: [],
          filter_ad_account: [],
          adv_filters_metrics: [],
          campaignStatus: 'All',
          bidStrategy: 'All',
          perfRange: [moment().startOf('day'), moment().endOf('day')],
          publisher_id: [],
          campaignName: "",
          lastChangedRange: [],
          createdAtRange: [],
          displayName: "",
          showNew: false,
          bookmarked: null,
          learningMode: 'all'
        },
        multiEdit: {
          isOpen:       false,
          selectedIds:  []
        },
        countries: COUNTRIES.countries,
        devices: [
          {value: "ios", text: "IOS"},
          {value: "android", text: "Andriod"},
          {value: "pc", text: "Desktop"},
          {value: "mobile", text: "Mobile"},
          {value: "all", text: "All"}
        ],
        limit: this.$isProd ? 30 : 30,
        currentPage: 1,
        totalRows: 0,
        campaigns_error: '',
        refreshTimeout: 0,
        refreshTotalsTimeout: 0,
        campaignStatuses: ['All', 'paused', 'running', 'terminated', 'in_review', 'mismatch', 'creating', 'create_error'],
        campaignStatus: 'All',
        bidStrategies: {'All': "All", "Bid Cap": 'LOWEST_COST_WITH_BID_CAP', "Lowest Cost": 'LOWEST_COST_WITHOUT_CAP' },
        bidStrategy: 'All',
        adv_filters_metrics: [],
        filter_device: [],
        filter_vp: [],
        filter_platform: [],
        filter_utm_medium: '',
        filter_campaign_id: '',
        filter_assignee: [],
        filter_author: [],
        filter_ad_account: [],
        learningMode: 'all',
        locales: [],
        assignees: [],
        isDownloadingReport: false,
        isRefreshing: false,
        dismissSecs: 120,
        dismissCountDown: 0,
        alertType: 'danger',
        alertError: '',
        isMobile: false,
        showNew: false,
        bookmarked: null,
        totals: {},
        notifications: {},
        interval: null,
        campaignName: "",
        displayName: "",
        showPerfModal: false,
        numeral: numeral,
        sort_by: null,
        sort_dir: 'desc',
        publisher_id: [],
        publishers: PUBLISHERS,
        moment: moment,
        campaigns: null,
        tiny_perfs: {},
        tiny_perfs_charts: {},
        revenues: {},
        rpms: {},
        units: {},
        availableTimezones: AVAILABLE_TIMEZONES,
        videoPlayerOptions: VIDEO_PLAYER_OPTIONS.filter(vp => vp.value != null),
        timezone: localStorage.getItem("tz") || 'UTC',
        datePickerRanges: datePickerRanges,
        perfRange: [moment().startOf('day'), moment().endOf('day')],
        lastChangedRange: [],
        createdAtRange: [],
        edits: {},
        perfRangeOptions: {
          alwaysShowCalendars: true,
          maxSpan: { "days": 365 },
          maxDate: moment().add(1,'days'),
          autoApply: true,
          ranges: datePickerRanges(),
        },
        lastChangedRangeOptions: {
          alwaysShowCalendars: true,
          maxSpan: { "days": 14 },
          maxDate: moment().add(1,'days'),
          autoApply: false,
          timePicker: true,
          singleDatePicker: true,
          ranges: {
            '1h ago': [moment().local().subtract(1, 'hours')],
            '2h ago': [moment().local().subtract(2, 'hours')],
            '3h ago': [moment().local().subtract(3, 'hours')],
            '4h ago': [moment().local().subtract(4, 'hours')],
            '5h ago': [moment().local().subtract(5, 'hours')],
            '6h ago': [moment().local().subtract(6, 'hours')],
          },
          locale: {
            cancelLabel: 'Clear',
            format: 'DD/M hh:mm A'
          }
        },
        createdAtRangeOptions: {
          alwaysShowCalendars: true,
          maxSpan: { "days": 365 },
          maxDate: moment().add(1,'days'),
          autoApply: false,
          ranges: datePickerRanges(),
          locale: {
            cancelLabel: 'Clear',
            format: 'DD/M hh:mm A'
          }
        },
        sentiments: {},
        budgets: {},
        realtime_users: {},
        currentCampaign: null,
        selectedDate: {
          start: moment().startOf('day'),
          end: moment().endOf('day')
        },
        filter_last_changed: null,
        filter_created_at: {
        },
        isResetting: false,
        lastCancelToken: null,
        showAdsets: null,
        nameTooltip: null,
        autoRefresh: true,
        disableAutoRefresh: false,
        selectedColumns: [],
        adAccounts: [],
        lockedCampaign: null,
        realTimeUsers: null,
        showRefreshWarning: false
      }
    },
    watch: {
      autoRefresh(v) {
        v ? this.schedRefresh() : this.cancelRefresh();
      },
      tableReloaded() {
        this.selectedColumns = STORAGE.get();
      },
      nameTooltip(n, o) {
        if (o) this.$root.$emit('bv::hide::tooltip', `${o}-tooltip-name`)
        if (n) this.$root.$emit('bv::show::tooltip', `${n}-tooltip-name`)
      },
      currentPage: function() {
        this.sentiments = {};

        this.refresh(false, true);
      },
      perfRange: function(n) {
        this.selectedDate.start = moment(n[0], 'DD/MM/YYYY').startOf('day');
        this.selectedDate.end = moment(n[1], 'DD/MM/YYYY').endOf('day');
        // TODO: Remove this once we have rollup per TZ
        const diff = this.selectedDate.end.diff(this.selectedDate.start, 'days', false); 
        
        if (diff > 13 && this.timezone != 'UTC')
        {
          alert('Cant work on these ranges on TZ other than UTC');
          window.location.reload();
        }

        // TODO: Check live updates
        // this.disableAutoRefresh = (!moment().startOf('day').isSame(this.selectedDate.start) || !moment().endOf('day').isSame(this.selectedDate.end));
        // if (this.disableAutoRefresh) this.autoRefresh = false;
        // this.subscribeToCampaignUpdates()
      },
      lastChangedRange: function(n) {
        this.filter_last_changed = moment.tz(n, 'DD/MM/YYYY HH:mm', moment.tz.guess()).local()
        if (!this.isResetting) this.refresh(true);
      },
      createdAtRange: function(n) {
        this.filter_created_at.start = moment(n[0], 'DD/MM/YYYY').startOf('day');
        this.filter_created_at.end = moment(n[1], 'DD/MM/YYYY').endOf('day');
      }
    },
    mounted() {
      LOGGER.initSession('Campaigns', this.$http, this.host)
      moment.tz.setDefault(this.timezone);
      this.selectedColumns = STORAGE.get();
      this.addLocales();
      this.resetSortBy();
      this.unloadFiltersFromURL();
      // this.$set(this, 'autoRefresh', !this.liveUpdatesEnabled) // TODO: Check live updates
      this.schedRefresh();

      this.loadAdAccounts();

      this.$eventHub.$on("resetBadges", (data) => {
        this.notifications[data.campaign.id] = null;
        this.$forceUpdate();
      })

      this.$eventHub.$on("syncNoteIcons", (data) => {
        this.campaigns_data[data.campaignName].has_note = data.hasNote;
        this.$forceUpdate();
      })

      this.$root.$on('bv::popover::show', bvEventObj => {
        bvEventObj.target.click();
      })

      this.isMobile = document.querySelector('body').offsetWidth < 767;

      this.$http.get(`${this.host}/users`, { params: { 'filter': 'assignee' } }).then(res => {
        this.assignees = res.data.users
      });

      this.$cable.subscriptions.create({
        channel: 'AnalyticsChannel',
      }, {
        received: this.handlePayloadReceived,
        connected: function() {
          this.perform("hello", {})
        }
      });
      // this.subscribeToCampaignUpdates(); // TODO: Check live updates
    },
    computed: {

      allPlatformsSelected() { 
        return this.filter_platform.length == 0;
      },
      liveUpdatesEnabled: function() {
        return false // TODO: Check live updates
        return this.timezone == 'UTC' && moment().startOf('day').isSame(this.selectedDate.start);
      },
      computedAdAccounts: function() {
        return this.adAccounts.filter(ad => {
          let isValid = true;

          if (!this.allPlatformsSelected)
            isValid = this.filter_platform.includes(ad.platform)

          if (this.publisher_id.length > 0)
            isValid = isValid && this.publisher_id.filter(pub => pub.id == ad.publisher_id).length > 0;

          return isValid;
        });
      },
      selectedColumnsData: function() {
        return COLUMNS.getSelectedData(this.selectedColumns)
      },
      campaigns_data: function() {
        return this.campaigns;
      },
      realtime_users_data: function() {
        return this.realtime_users;
      },
      getSpendsForCPA: function()
      {
        let spends = 0;
        for(let camp in this.campaigns)
        {
          if (this.campaigns[camp].results > 0)
            spends += this.campaigns[camp].spend;
        }

        return spends;
      },
      allSelected: {
        set (selected) {
          this.multiEdit.selectedIds = selected ? this.campaigns.map(c => c.id) : [];
        },
        get () {
          return this.multiEdit.selectedIds.length == this.campaigns.length
        }
      }
    },
    beforeDestroy() {
      LOGGER.terminateSession();
      this.cancelRefresh();
      this.$eventHub.$off("badgeReset");
      this.$eventHub.$off("syncNoteIcons");
      this.$cable.subscriptions.subscriptions.forEach(s => s.unsubscribe());
    },
    beforeCreate() {
      moment.tz.setDefault("UTC");
    },
    methods: {
      assigneesFullName ({first_name, last_name}) {
        return `${first_name} ${last_name}`;
      },
      isPlatformSelected(platform) {
        return !this.allPlatformsSelected && this.filter_platform.includes(platform)
      },
      updatePlatformFilter (platform) {
        if (platform == 'all') {
          this.filter_platform = [];
        } else {
          this.filter_platform.includes(platform) ? this.filter_platform = this.filter_platform.filter(plat => plat != platform) : this.filter_platform.push(platform);
        }
      },
      refreshTextField (_e) {
        if (!this.isResetting) this.refresh(true);
      },
      getPlatformColor(platform) {
        return this.platformOptions(platform).color;
      },
      addLocales() {        
        for (let platform of Object.keys(platformOptions))
        {
          for (let locale of JSON.parse(JSON.stringify(platformOptions[platform].locales)))
          {            
            let exist = this.locales.filter(x => locale.name == x.name)[0];
          
            if (exist)
              exist.code += `,${locale.code}`;
            else
              this.locales.push(locale);
          }
        }
      },
      changeTimezone(n) {
        localStorage.setItem("tz", n);
        this.timezone = n;
        moment.tz.setDefault(n);

        let start = moment(this.selectedDate.start, "DD/MM/YYYY").tz(n, true).startOf("day");
        let end = moment(this.selectedDate.end, "DD/MM/YYYY").tz(n, true).endOf("day");
        this.$set(this, "selectedDate", { start, end });

        this.$set(this.perfRangeOptions, "ranges", this.datePickerRanges());
        this.$set(this.perfRangeOptions, "startDate", start);
        this.$set(this.perfRangeOptions, "endDate", end);
        this.$set(this, "perfRange", [start, end]);
      },
      subscribeToCampaignUpdates() {
        const metricsUpdatedSubscription = this.$cable.subscriptions.subscriptions.find(
          s => s.identifier.includes("CampaignsMetricsUpdatedChannel")
        );

        if (this.liveUpdatesEnabled && !metricsUpdatedSubscription) {
          this.schedTotalsRefresh();
          this.$cable.subscriptions.create({
            channel: 'CampaignsMetricsUpdatedChannel',
          }, {
            received: this.handleMetricsUpdated
          })
        } else {
          if (this.refreshTotalsTimeout) clearTimeout(this.refreshTotalsTimeout);
          metricsUpdatedSubscription.unsubscribe();
        }
      },
      statusIcons(status) {
        return ICONS.status[status]
      },
      isSingleCountry(countries) {
        return (countries.length == 1 || countries.every(c => c == countries[0])) && countries[0] != 'all'
      },
      markCampaign(campaign)
      {
        this.lockedCampaign = campaign.id;
      },
      loadAdAccounts() {
        this.$http.get(`${this.host}/ad_accounts`).then(res => {
          this.adAccounts = res.data.ad_accounts;
        })
      },
      isColumnSelected(colName){
        return this.selectedColumns.find(x => x === colName);
      },
      resetSortBy(){
        const DEFAULT_SORT_BY = 'clicks'
        const { selectedColumns } = this

        if ((selectedColumns || []).includes(DEFAULT_SORT_BY)) {
          this.sort_by = DEFAULT_SORT_BY
        } else {
          this.sort_by = selectedColumns.find(COLUMNS.isColMetricsAgg)
        }
      },
      toggleAdsets(campaign) {
        this.nameTooltip = null;

        this.showAdsets = ( this.showAdsets === campaign.id ) ? null : campaign.id;
      },
      isAdsetCollapted(campaign)
      {
        return this.showAdsets === campaign.id
      },
      toggleFullNameTooltip(campaign_id, e) {
        if (this.isMobile) return;
        if (campaign_id !== this.nameTooltip) this.nameTooltip = null;

        if (e.target.offsetWidth < e.target.scrollWidth) {
          this.nameTooltip = campaign_id;
        }
      },
      alertErrorSetter(alertError, alertType) {
        this.alertError = alertError;
        this.alertType = alertType;
        this.dismissCountDown = this.dismissSecs;
      },
      externalLink(content)
      {
        switch(content.platform) {
          case 'facebook':
            return `https://business.facebook.com/adsmanager/manage/all?act=${content.ad_account}&business_id=487945378071353&selected_campaign_ids=${content.external_id}&root_level=ad_set`;
          // case 'twitter':
          //   let funding = content.publisher == 'daquan.tv' ? '52520754' : '52459603' // Fix when we'll expand
          //   return `https://ads.twitter.com/ads_manager/${content.ad_account}/fundingsources/${funding}/campaigns/${parseInt(content.external_id, 36)}/adgroups/`;
          case 'snapchat':
            return `https://ads.snapchat.com/${content.ad_account}/campaigns/${content.external_id}`;
          case 'pinterest':
            return `https://ads.pinterest.com/advertiser/${content.ad_account}/reporting/campaigns/?name=${content.name}`;
          case 'taboola':
            return `https://backstage.taboola.com/backstage/1176118/campaign/${content.external_id}/inventory`;
        }
      },
      wpLink(content)
      {
        if (!content.metadata)
          return;
          
        let article_id = content.metadata.utm_medium;
        let url = content.publisher.replace("www.", "");
        return `https://editors.${url}/wp-admin/post.php?post=${article_id}&action=edit`
      },
      unloadFiltersFromURL() {
        const COLLAPSED_FILTERS = ['filter_device', 'filter_ad_account', 'campaignName', 'adv_filters_metrics']
        let urlFilters = Object.assign({}, this.$route.query)
        let showCollapsed = false

        Object.keys(urlFilters).forEach(filter => {

          if (!UTILS.isEqual(urlFilters[filter], this[filter])) {

            this[filter] = JSON.parse(urlFilters[filter])

            if (COLLAPSED_FILTERS.includes(filter)) showCollapsed = true
          }

        })
        this.updateDatePickersDisplay()
        if (showCollapsed) this.$root.$emit('bv::toggle::collapse', 'adv-filtering')

        this.refresh(true)
      },
      saveFiltersToURL() {
        let filters = {}

        Object.keys(this.defaultFilters).forEach(filter => {
          if (!UTILS.isEqual(this[filter], this.defaultFilters[filter])) {
            filters[filter] = JSON.stringify(this[filter])
          }
        })

        let q = Object.keys(filters).length > 0 ? `?${new URLSearchParams(filters).toString()}` : ''
        history.pushState({}, '', `#${this.$route.path}${q}`);
      },
      clearFilters()
      {
        this.isResetting = true;
        for (let filter in this.defaultFilters)
          this[filter] = Array.isArray(this.defaultFilters[filter])
            ? [...this.defaultFilters[filter]]
            : this.defaultFilters[filter];

        this.clearAdvFilter();
        this.updateDatePickersDisplay();

        this.$nextTick(() => {
          this.isResetting = false;
          this.refresh(true);
        });
      },
      updateDatePickersDisplay() {
        Object.keys(this.defaultFilters).filter(f => f.includes('Range')).forEach(range => {
          let clonedRange = UTILS.clone(this[range])

          if (this[`${range}Options`].singleDatePicker) {
            clonedRange = Array(2).fill(clonedRange)
          }

          this.$set(this[`${range}Options`], 'startDate', clonedRange[0])
          this.$set(this[`${range}Options`], 'endDate', clonedRange[1])
        })
      },
      handleMetricsUpdated(payload) {
        if (!payload.length) return

        this.$set(
          this, "campaigns",
          this.campaigns.map(campaign => {
            const updatedCampaign = payload.find(c => c.id === campaign.id);
            if (updatedCampaign && updatedCampaign.budget) {

              if (!this.edits[campaign.id])
                this.$set(this.budgets, campaign.id, updatedCampaign.budget);
            }
            if (updatedCampaign && updatedCampaign.tiny_perf) {
              this.$set(this.tiny_perfs, campaign.id, updatedCampaign.tiny_perf);
              this.destroyTinyPerf(campaign.id);
              this.displayTinyPerf(campaign.id);
            }
            return Object.assign(campaign, updatedCampaign);
          })
        );

        this.animateCampaigns(payload);
        this.checkOffscreenCampaigns(payload);
      },
      checkOffscreenCampaigns(updatedCampaigns) {
        let showRefreshWarning;

        if (!this.campaigns.length) {
          showRefreshWarning = updatedCampaigns.length > 0;
        } else {
          const filteredOffscreenCampaigns = updatedCampaigns.filter(c => {
            const isOffscreen = !this.campaigns.some(c2 => c2.id === c.id);
            const matchesFilters = this.campaignMatchesFilters(c);
            return isOffscreen && matchesFilters
          });
          const min = Math.min(...this.campaigns.map(c => c[this.sort_by]))
          const max = Math.max(...this.campaigns.map(c => c[this.sort_by]))

          showRefreshWarning = filteredOffscreenCampaigns.some(c1 => {
            if (this.currentPage == 1) {
              return this.sort_dir == "asc"
                ? c1[this.sort_by] <= max
                : c1[this.sort_by] >= min;
            } else {
              return c1[this.sort_by] <= max && c1[this.sort_by] >= min;
            }
          });
        }

        this.$set(this, "showRefreshWarning", showRefreshWarning);
      },
      campaignMatchesFilters(campaign) {
        let matchesFilters = Object.keys(this.defaultFilters)
          .filter(f => f.startsWith("filter_"))
          .every(f => {
            const filter_value = this[f];
            const filter_attribute = f.replace("filter_", "");
            const campaign_value = campaign[filter_attribute];
            const array_campaign_value =
              campaign[`${filter_attribute}s`] || // devices
              campaign[`${filter_attribute.replace(/.$/, "ies")}`]; // countries
            const id_campaign_value =
              campaign[`${filter_attribute}_id`] || // assignee_id
              (campaign["metadata"] && campaign["metadata"][`${filter_attribute}_id`]); // ad_account_id

            const isEmptyFilter = !filter_value;
            const matchesFilterValue = campaign_value == filter_value;
            const matchesFilterId = id_campaign_value && id_campaign_value == filter_value;
            const matchesFilterInArray = array_campaign_value && array_campaign_value.some(cv => cv == filter_value || isSameCountry(cv, filter_value));
            return isEmptyFilter || matchesFilterValue || matchesFilterId || matchesFilterInArray;
          });
        const matchesFilterStatus = this.campaignStatus.toLowerCase() == "all" || campaign.status == this.campaignStatus.toLowerCase();
        const matchesFilterPublisher = this.publisher_id.length == 0 || this.publisher_id.fliter(pub => pub.id == campaign.metadata.publisher_id).length > 0;
        matchesFilters = matchesFilters && matchesFilterStatus && matchesFilterPublisher

        return matchesFilters;
      },
      animateCampaigns(campaigns) {
        campaigns.forEach(c => {
          let rowRef = this.$refs[`campaign-${c.id}`];
          if (rowRef)
            this.animateRow(rowRef[0]);
        });
      },
      animateRow(rowElem) {
        if (rowElem) {
          rowElem.classList.remove("updated");
          rowElem.offsetWidth; // trigger reflow to "restart" animation
          rowElem.classList.add("updated");
        }
      },
      handlePayloadReceived(payload) {
        if (!payload)
          return;

        this.realTimeUsers = payload.realtime;
        this.updateRealTimeUsers()
      },
      updateRealTimeUsers() {
        if (this.campaigns && this.realTimeUsers) {
          this.campaigns.forEach(camp => {
            if (this.realTimeUsers[camp.id])
              this.$set(camp, 'realtimeUsers', this.realTimeUsers[camp.id]);
          })
        }
        this.totals['realtimeUsers'] = Object.values(this.realTimeUsers || {}).reduce((acc, val) => acc + val, 0)
      },
      getTinyPeftMood(campaign_id)
      {
        if (!this.tiny_perfs[campaign_id])
          return;

        let perf = this.tiny_perfs[campaign_id];
        let len = perf.length;

        if (perf[len - 2] > perf[len - 1])
          return '#dc3545';

        return '#27c414'
      },
      removeAdvFilter(metric)
      {
        this.adv_filters_metrics = this.adv_filters_metrics.filter(e => {
          return e != metric
        })
      },
      clearAdvFilter()
      {
        this.adv_filters_metrics = []
      },
      addAdvFilter()
      {
        this.adv_filters_metrics.push({ })
      },
      getTitleForDevice(devices) {
        return DEVICES_DISPLAY.title(devices)
      },
      getClassForDevice(devices) {
        return DEVICES_DISPLAY.icon(devices)
      },
      publisherObject(content) {
        return PUBLISHERS.find(pub => {
          return pub.url.indexOf(content.publisher) > -1;
        }) || {};
      },
      colorPick(content) {
        if (this.lockedCampaign == content.id)
          return 'locked-campaign campaign-row';
        else if (this.edits[content.id])
          return 'edit-mode';
        else if (this.showAdsets == content.id)
          return 'open-adsets';
        else if (moment(new Date()).diff(content.user_changed_at, 'minutes') <= 240)
          return 'bookmarked campaign-row';
        else if (content.complement)
          return 'complement campaign-row'
        else if (content.bookmarked)
          return 'bookmarked campaign-row'
        else
          return 'basic-color campaign-row'
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      bookmark(campaign)
      {
        this.$http.put(`${this.host}/campaigns/${campaign.id}/bookmark`, {
          bookmark: !campaign.bookmarked
        }).then(() => {
          campaign.bookmarked = !campaign.bookmarked
        })
      },
      saveCampaignDailyBudget(campaign) {
        alog(`Campaign Daily Budget Update (camp ${campaign.id})`, this.budgets[campaign.id].oldDailyBudget, this.budgets[campaign.id].daily_budget)
        if (!VALIDATION.budget(this.budgets[campaign.id].daily_budget, campaign.platform)) return;

        this.$http.put(`${this.host}/campaigns/${campaign.id}/update_external`, {
          attribute: 'daily_budget',
          value: this.budgets[campaign.id].daily_budget
        }).then(res => {
          this.alertError = 'Daily Budget updated successfully'
          this.dismissCountDown = this.dismissSecs;
          this.alertType = 'success';
          this.budgets[campaign.id].oldDailyBudget = this.budgets[campaign.id].daily_budget;
          ilog(`${this.alertError} (camp ${campaign.id})`)
          this.refreshCampaignLastChanged(res.data)
        }).catch(ex => {
          this.alertError = ex.response.data.error
          this.dismissCountDown = this.dismissSecs;
          this.alertType = 'danger';
          this.budgets[campaign.id].oldDailyBudget = this.budgets[campaign.id].daily_budget;
          elog(`${this.alertError} (camp ${campaign.id})`, ex)
        }).finally(() => {
          delete this.edits[campaign.id];
        })

      },
      revertCampaignDailyBudget(campaign) {
        alog(`revert campaign daily budget (camp ${campaign.id})`, this.budgets[campaign.id].daily_budget, this.budgets[campaign.id].oldDailyBudget)
        this.budgets[campaign.id].daily_budget = this.budgets[campaign.id].oldDailyBudget;
        delete this.edits[campaign.id];
        this.$forceUpdate();
      },
      editCampaignDailyBudget(campaign) {
        this.budgets[campaign.id].oldDailyBudget = this.budgets[campaign.id].daily_budget;
        this.edits[campaign.id] = {
          isEditingDailyBudget: true
        };
        this.$forceUpdate();
      },
      refreshCampaignLastChanged(data) {
        let i = this.campaigns.findIndex(c => c.id === data.id)
        this.campaigns[i].last_audit_ago = data["last_audit_ago"]
      },
      getExternalCampaignLink(campaign) {
        if (campaign.platform == 'facebook')
          return `https://business.facebook.com/adsmanager/manage/adsets?act=${campaign.ad_account}&business_id=487945378071353&columns=name
        %2Cerrors%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cresults%2Creach%2Cimpressions%2C
        cost_per_result%2Cspend%2Cend_time%2Cschedule%2Crelevance_score%3Ascore%2Cfrequency%2Cunique_actions%3A
        link_click%2Cactions%3Alanding_page_view%2Cactions%3Alink_click%2Ccost_per_action_type%3
        Alanding_page_view&selected_campaign_ids=${campaign.external_id}`;
        else if (campaign.platform == 'twitter')
          return `https://ads.twitter.com/ads_manager/${campaign.ad_account}/fundingsources/52520754/campaigns/${campaign.external_id}/adgroups/`
        else
          return '';
      },
      onBulkStatusChange(campaignIds, newStatus) {
        const campaigns = this.campaigns.filter(c => campaignIds.includes(c.id));
        this.multiEditCampaigns(campaigns, 'status', newStatus);
      },
      onBudgetChange(campaignIds, budget) {
        const campaigns = this.campaigns.filter(c => campaignIds.includes(c.id));
        this.multiEditCampaigns(campaigns, 'daily_budget', budget);
      },
      onMaxBidChange(campaignIds, bid) {
        const campaigns = this.campaigns.filter(c => campaignIds.includes(c.id));
        this.multiEditCampaigns(campaigns, 'max_bid', bid);
      },
      multiEditCampaigns(campaigns, action, value) {
        if (this.working || campaigns.some((c) => !c.status.match(/running|paused|create_error/))) return;

        const errorCampaigns = campaigns.filter((c) => c.status === "create_error");
        if (errorCampaigns.length > 0) {
          for (const campaign of errorCampaigns) {
            this.creationRetry(campaign);
          }
          return;
        }

        const campignNames = campaigns.map((c) => c.name);
        if (
          !confirm(`You are about to apply action ${action} with value of ${value} on the following campaigns: ${campignNames.join("\n")}\nContinue?`)
        ) return;

        campaigns.forEach((c) => {
          c.status = "pending";
        });

        const campaignIds = campaigns.map((c) => c.id);
        const thisVueComponent = this;
        this.$http
          .put(`${this.host}/campaigns/multi_update`, {
            campaign_ids: campaignIds,
            action_name: action,
            value: value
          })
          .then((res) => {
            this.alertType = "info";
            this.alertError = "Campaigns updated successfully";
            const data = res.data;
            data.succeeded.forEach((succeeded) => {
              const campaign = thisVueComponent.campaigns.find(c => c.id === succeeded.id);
              if (action === 'daily_budget') thisVueComponent.budgets[campaign.id].oldDailyBudget = campaign.daily_budget;
              if (campaign) Object.assign(campaign, succeeded);
              else throw new Error('Error replacing updated campaign');
            });
            thisVueComponent.campaigns = [...thisVueComponent.campaigns];
            this.showAdsets = null;
            this.nameTooltip = null;
            this.dismissCountDown = this.dismissSecs;
            this.$forceUpdate();
            this.refresh();

            const adsetActivateCampaigns = campaigns.filter(
              (c) => c.status === "running" && c.platform !== "taboola"
            );
            if (adsetActivateCampaigns.length > 0)
              alert(`Make sure to activate the adsets manually for the following campaigns: ${adsetActivateCampaigns.map((c) => c.id)}`);

            ilog(`${this.alertError} (camps ${campaignIds})`);
          })
          .catch((err) => {
            this.alertType = "danger";
            this.alertError = `${err.response.data.succeeded.length > 0
              ? `Campaign status update SUCCEEDED for:\n${err.response.data.succeeded.join(", ")}\n`
              : ""
              }
                  Campaign status update FAILED for:\n${err.response.data.failed.map(
                (c) => `Campaign Id: ${c.campaign_id}\nReason: ${c.error}`
              ).join("\n\n")}`;

            this.dismissCountDown = this.dismissSecs;
            elog(`${this.alertError} (camps ${campaignIds})`, err);
          })
          .finally(() => {
            this.working = false;
          });
      },
      openStatusChange(campaigns, newStatus) {
        if (this.working || campaigns.some((c) => !c.status.match(/running|paused|create_error/))) return;

        const errorCampaigns = campaigns.filter((c) => c.status === "create_error");
        if (errorCampaigns.length > 0) {
          for (const campaign of errorCampaigns) {
            this.creationRetry(campaign);
          }
          return;
        }

        if (
          confirm(
            `You are about to ${
              newStatus == "running" ? "activate" : "pause"
            } the following campaigns: ${campaigns.map((c) => c.name).join("\n")}
            \nContinue?`
          )
        ) {
          campaigns.forEach((c) => {
            c.status = "pending";
            alog(`Campaigns status change (camp ${c.id})`, c.status, newStatus);
          });

          const campaignIds = campaigns.map((c) => c.id);
          this.$http
            .put(`${this.host}/campaigns/update_status`, {
              campaign_ids: campaignIds,
              status: newStatus,
            })
            .then((_res) => {
              this.alertType = "info";
              this.alertError = "Campaigns status updated successfully";
              campaigns.forEach((c) => (c.status = newStatus));
              this.showAdsets = null;
              this.nameTooltip = null;
              this.dismissCountDown = this.dismissSecs;
              this.$forceUpdate();
              this.refresh();

              const adsetActivateCampaigns = campaigns.filter(
                (c) => c.status === "running" && c.platform !== "taboola"
              );
              if (adsetActivateCampaigns.length > 0)
                alert(`Make sure to activate the adsets manually for the following campaigns: ${adsetActivateCampaigns.map((c) => c.id)}`);

              ilog(`${this.alertError} (camps ${campaignIds})`);
            })
            .catch((err) => {
              this.alertType = "danger";
              this.alertError = `${
                err.response.data.succeeded.length > 0
                  ? `Campaign status update SUCCEEDED for:\n${err.response.data.succeeded.join(", ")}\n`
                  : ""
              }
              Campaign status update FAILED for:\n${err.response.data.failed.map(
                (c) => `Campaign Id: ${c.campaign_id}\nReason: ${c.error}`
              ).join("\n\n")}`;

              this.dismissCountDown = this.dismissSecs;
              elog(`${this.alertError} (camps ${campaignIds})`, err);
            })
            .finally(() => {
              this.working = false;
            });
        }
      },
      creationRetry(campaign){
        if (!confirm(`Do you really want to retry creating ${campaign.name} ?`))
          return;

        this.working = true;
        this.$http.get(`${this.host}/campaigns/${campaign.id}/retry_create`)
          .then(res => {
            this.alertType        = 'info';
            this.alertError       = 'Campaign sent to retry';
            campaign.status       = res.data.status;
            this.showAdsets       = null;
            this.nameTooltip      = null;
            this.dismissCountDown = this.dismissSecs;
          })
          .catch(err => {
            this.alertError       = `Updating campaign status failed\n${err.response.data.error || err.response.data }`;
            this.alertType        = 'danger';
            campaign.status       = 'create_error';
            this.dismissCountDown = this.dismissSecs;
            elog(`${this.alertError} (camp ${campaign.id})`, err);
          })
          .finally(() => {
            this.working = false
          })
      },
      isTinyPerfEmpty(campaign_id) {
        return this.tiny_perfs[campaign_id].every(val => val === 0)
      },
      destroyTinyPerf(campaign_id)
      {
        if (this.tiny_perfs_charts[campaign_id])
        {
          this.tiny_perfs_charts[campaign_id].destroy();
          this.tiny_perfs_charts[campaign_id] = null;
        }
      },
      displayTinyPerf(campaign_id)
      {
        if (!this.tiny_perfs[campaign_id])
          return;

        if (this.tiny_perfs_charts[campaign_id])
        {
          this.tiny_perfs_charts[campaign_id].data.datasets[0].data = this.tiny_perfs[campaign_id];
          this.tiny_perfs_charts[campaign_id].update()
          return;
        }

        let sl = document.getElementById(`sparkline-${campaign_id}`)

        if (!sl)
          return;

        sl = sl.getContext('2d');

        if (this.isTinyPerfEmpty(campaign_id))
          return;

        // eslint-disable-next-line
        let chart = new Chart(sl, {
          type: 'line',
          data: {
            labels: Array(this.tiny_perfs[campaign_id].length).fill(0),
            datasets: [
              {
                data: this.tiny_perfs[campaign_id]
              }
            ]
          },
          options: {
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0,
            },
            responsiveAnimationDuration: 0,
            responsive: false,
            legend: {
              display: false
            },
            elements: {
              line: {
                borderColor: this.getTinyPeftMood(campaign_id),
                borderWidth: 2,
                backgroundColor: this.getTinyPeftMood(campaign_id) + "5f"
              },
              point: {
                radius: 0
              }
            },
            tooltips: {
              enabled: false
            },
            scales: {
              yAxes: [
                {
                  display: true,
                  ticks: {
                    min: Math.min(...this.tiny_perfs[campaign_id], -0.05),
                    max: Math.max(...this.tiny_perfs[campaign_id], 0.05),
                    display: false,
                    beginAtZero: true
                  }
                }
              ],
              xAxes: [
                {
                  display: false,
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: false
                  }
                }
              ]
            }
          }
        });

        this.tiny_perfs_charts[campaign_id] = chart;
      },
      getFilteredParams()
      {
        return {
          bookmarked: this.bookmarked,
          show_new: this.showNew,
          sort_by: this.sort_by,
          sort_dir: this.sort_dir,
          campaign_name: this.campaignName,
          device: this.filter_device.map(dev => dev.value),
          platform: this.filter_platform,
          utm_medium: this.filter_utm_medium,
          campaign_id: this.filter_campaign_id,
          assignee: this.filter_assignee.map(ass => ass.id),
          author: this.filter_author.map(author => author.id),
          vp: this.filter_vp.map(vp => vp.value),
          filters: this.adv_filters_metrics,
          last_changed: this.filter_last_changed,
          created_at: this.filter_created_at,
          campaign_status: this.campaignStatus.toLowerCase(),
          bid_strategy: this.bidStrategy.toLowerCase(),
          display_name: this.displayName,            
          ad_account: this.filter_ad_account.map(acc => acc.id),
          start: this.selectedDate.start,
          end: this.selectedDate.end,
          publisher_id: this.publisher_id.map(pub => pub.id),
          user_id: this.$user.id,
          columns: this.selectedColumns.filter(COLUMNS.isColMetricsAgg),
        }
      },
      getReport() {
        if (!confirm('Export?'))
          return;

        this.isDownloadingReport = true;
        this.$http.get(`${this.host}/campaigns/export`, {
          responseType: 'blob',
          method: 'GET',
          params: this.getFilteredParams()
        }).then(() => {
          alert("Report will be sent to your email soon")
        }).finally(() => {
          this.isDownloadingReport = false;
        });
      },
      openPerfModal(campaign) {
        this.currentCampaign = campaign;
        this.$nextTick(() => {
          this.$refs.perfModal.show()
        })
      },
      loadRevenues: function(campaign_id) {
        if (!campaign_id)
          return;

        this.revenues = {};
        this.$http.get(`${this.host}/campaigns/${campaign_id}/revenues`, {
          params: this.getFilteredParams()
        }).then(res => {
          this.revenues = res.data.revenues
        })
      },
      loadRpms: function(campaign_id) {
        if (!campaign_id)
          return;

        this.rpms = {};
        this.$http.get(`${this.host}/campaigns/${campaign_id}/rpms`, {
          params: this.getFilteredParams()
        }).then(res => {
          this.rpms = res.data.rpms
        })
      },
      schedTotalsRefresh() {
        if (this.$isProd) {
          if (this.refreshTotalsTimeout) clearTimeout(this.refreshTotalsTimeout);
          this.refreshTotalsTimeout = setTimeout(this.refreshTotals, 30000)
        }
      },
      schedRefresh() {
        if (this.$isProd && this.autoRefresh) {
          if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
          this.refreshTimeout = setTimeout(this.refresh, 30000)
        }
      },
      cancelRefresh() {
        if (this.$isProd) {
          if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
        }
      },
      changeSort: function(metric) {
        if (!COLUMNS.isColMetricsAgg(metric)) return;

        if (this.sort_by == metric && this.sort_dir == 'desc')
          this.sort_dir = 'asc';
        else
          this.sort_dir = 'desc';

        this.sort_by = metric;
        this.refresh(true);
      },
      refreshTotals: function() {
        this.$http.get(`${this.host}/campaigns/`, {
          params: {
            offset: (this.currentPage - 1) * this.limit,
            limit: this.limit,
            campaign_ids: this.campaigns.map(c => c.id),
            skip_metrics: true,
            ...this.getFilteredParams(),
          }
        }).then(res => {
          this.$set(this, 'totals', Object.assign(this.totals, res.data.totals));
          this.$set(this, 'notifications', res.data.notifications);
        })
      },
      refresh: function(reset, force) {
        
        if (force) {
          this.edits = {}
        }

        if (Object.keys(this.edits).length > 0)
        {
          this.schedRefresh();
          return;
        }

        if (reset) {
          this.campaigns = null;
          this.currentPage = 1;
        }

        let learningMode = null
        if (this.learningMode != 'all')
          learningMode = this.learningMode == 'true'

        this.saveFiltersToURL();
        this.campaigns_error = '';
        this.isRefreshing = true;
        let _this = this;
        this.$http.get(`${this.host}/campaigns`, {
          params: {
            offset: (this.currentPage - 1)* this.limit,
            limit: this.limit,
            learning_mode: learningMode,
            columns: this.selectedColumns.filter(COLUMNS.isColMetricsAgg),
            ...this.getFilteredParams()
          },
          cancelToken: new CancelToken(function executor(c) {
            if (_this.lastCancelToken) _this.lastCancelToken('Filters were updated');
            _this.lastCancelToken = c;
          })
        }).then(res => {

          this.isRefreshing = false;
          this.lastCancelToken = null;

          if (Object.keys(this.edits).length > 0)
            return;

          this.campaigns = res.data.campaigns;
          this.totals = res.data.totals;
          this.notifications = res.data.notifications
          if (Object.keys(this.edits).length === 0)
            this.budgets = res.data.campaigns.reduce((budgets, campaign) =>
              ({ ...budgets, [campaign.id]: { daily_budget: campaign.metadata.daily_budget } }),
              {}
            )
          this.offset += this.campaigns.length;
          this.totalRows = res.data.count;

          this.$set(this, 'showRefreshWarning', false);
          let campaign_ids = this.campaigns.map(c => c['id'])

          if (this.selectedColumns.includes('comments') || this.selectedColumns.includes('negative_sentiment')) {
            this.$http.get(`${this.host}/campaigns/sentiments`, {
              params: {
                ...this.getFilteredParams(),
                campaigns: campaign_ids
              }
            }).then(res => {
              if (Object.keys(this.edits).length > 0) return;

              const { sentiment } = res.data
              this.campaigns.forEach(camp => {
                if (sentiment[camp.id]) {
                  this.$set(camp, 'comments', sentiment[camp.id].total)
                  this.$set(camp, 'negative_sentiment', sentiment[camp.id].negative)
                }
              })
            })
          }

          this.$http.get(`${this.host}/campaigns/tiny_perf`, { params: { campaigns: campaign_ids } }).then(res => {
            this.tiny_perfs = res.data.perfs;
            this.$nextTick(() =>{
              for (var k in this.tiny_perfs) {
                this.destroyTinyPerf(parseInt(k))
                this.displayTinyPerf(parseInt(k))
              }
            })
          }).finally(() => {

          });
        }).catch(err => {
          if (err.response) this.campaigns_error = err.response.data.error;
        }).finally(() => {
          this.isRefreshing = false;
          this.updateRealTimeUsers();
          this.schedRefresh();
        })
      },
      platformOptions(platform) { return OPTIONS.platform(platform) }
    },
    components: {PerfModal, ClipLoader, CountryFlag, AdsetRow, MultiEdit, UnitsTooltip, Multiselect}
  }
</script>

<style lang="scss">
  #panel {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .column {
      padding: 20px 5px 0 5px;

      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        @media (max-width: 420px) {
          width: 100%;
        }
      }

      &:nth-child(7) .field{
        @media (max-width: 420px) {
          margin-bottom: 5px;
        }
      }

      .field {
        display: flex;
        height: fit-content;
        margin-bottom: 5px;

        label {
          text-align: right;
          margin: 0;

          @media(max-width: 420px) {
            width: 110px !important;
            text-align: left;
          }

          &.short {
            width: 46px;
          }
          &.medium {
            width: 58px;
          }
          &.long {
            width: 73px;
          }
          &.longer {
            width: 120px;
            @media(max-width: 420px) {
              width: 125px !important;
            }
          }
        }
        input, select, .multiselect {
          height: 40px;
          width: 220px;
          padding-left: 16px;
          border: 0px;
          border-radius: 10px;
          font-size: 14px;

          @media(max-width: 420px) {
            width: 100%;
          }
        }
        .multiselect.input-field.long {
          width: 265px;

          @media(max-width: 420px) {
            width: 100%;
          }

          &.small {
            font-size: 11px;
          }
        }
        select {
          background-color: #9B9B9B;
          color: white;
        }
        input {
          background-color: #e0e0e0;
        }
        .multiselect {
          padding: 0;

          .multiselect__tags {
            background-color: #9B9B9B;
            border-radius: 10px;
            padding-left: 18px;

            span {
              background-color: #9B9B9B;
              color: white;
            }
          }
        }
        
        label + select,
        label + input,
        label + .multiselect {
          margin-left: 15px;

          @media(max-width: 420px) {
            margin-left: auto;
          }
        }

        &.text input {
          width: 200px;

          @media(max-width: 420px) {
            width: 100%;
          }
        }

        #lastChangeBefore {
          width: 130px;
        }
        #utmCampaign {
          width: 100%;
        }
      }

      .toggle-button {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        font-size: 16px;
        border: 1px solid #E0E0E0;
        text-align: center;
        cursor: pointer;
      }

      h3 {
        text-align: center;
        font-size: 16px;
      }

      .time-zone-buttons {
        width: 80px;
        height: 100px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .toggle-button {
          max-height: 32%;
          line-height: 32px;
          color: #4BCEAD;
          border-color:#4BCEAD;
          &.active {
            background-color: #4BCEAD;
            color: white;
            border-color: white;
          }
        }
      }

      .source-buttons {
        height: 120px;
        width: 120px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > * {
          height: 100%;
          max-height: 24%;
          display: flex;
          justify-content: space-between;

          .toggle-button {
            max-width: 48%;
          }
        }

        & > div > .toggle-button {
          justify-content: center;
          &.active {
            background-color: #4BCEAD;
            color: white;
            border-color: white;
          }
        }

        img {
          height: 21px;
        }
      }

      .checkbox {
        margin-left: 30px;
      }

      #export-button {
        height: 38px;
        width: 100px;
        color: white;
        background-color: black;
        border-radius: 7px;
        text-align: center;
        line-height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          height: 15px;
          margin-left: 5px;
        }
      }
    }
  }

  #panel-controller {
    margin: 15px 0px;

    .form-inline {
      margin-top: 15px;
    }

    & > * {
      margin-top: 15px;
    }

    .card-body {
      padding: 1em;
    }

    .button {
      border-radius: 8px;
      padding: 0.1em 2.5em;
      margin: 10px 15px 0px 0;
      color: white;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      border: 0px;

      &.red {
        background-color: #DB504A;
      }
      &.green {
        background-color: #4BCEAD;
      }
      &.blue {
        background-color: #696FFC;
      }
    }

    .input {
      display: flex;
      margin-left: 16px;
    }
    label {
      line-height: 30px;
      margin: 0px;
    }
    input, select, .multiselect {
      height: 30px;
      width: 220px;
      padding-left: 16px;
      border: 0px;
      border-radius: 10px;
      font-size: 14px;
      margin-left: 8px;
    }
    select {
      background-color: #9B9B9B;
      color: white;
    }
    input {
      background-color: #e0e0e0;
    }
    .multiselect {
      padding: 0;

      .multiselect__tags {
        background-color: #9B9B9B;
        border-radius: 10px;
        padding-left: 18px;

        span {
          background-color: #9B9B9B;
          color: white;
        }
      }
    }
    #autoRefresh {
      width: auto;
    }
  }

  .resize-cell {
    resize: horizontal;
    overflow: auto;
    width: auto;
  }

  .site-code {
    font-weight: 800;
    font-size: 18px;
  }

  .popover-custom-body {
    overflow: scroll;
    height: 400px;
  }

  .campaigns {

    &-container-header-th {
      background: #f9f9f9;
    }

    .period {
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .ad-account,
  .utm-campaign {
    margin-top: 8px;

    .input-field {
      width: 250px;
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .title {
      width: 85px;
    }
  }

  .utm-campaign {
    margin: 0;

    .input-field {
      width: 236px;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .open-adsets {
    background-color: #e8f5ff;
  }

  .campaign-name-title-text {
    max-width: 35vw;
    white-space: wrap;
    min-width: 20vw;
    cursor: pointer;
  }

  .campaign-name-title-text.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hidden-header {
    display: none;
  }

  .units
  {
    white-space: nowrap;
  }

  .sticky {
    top: 55px;
    z-index: 10;
  }

  .sticky .th{
    background-color: #dee2e6;
    position: sticky;
    top: 56px;
    z-index: 100;
    border: 0;
  }

  .sticky tr:first-child {
    border-bottom-style: hidden;
  }

  .sticky tr:last-child {
    border-top-style: hidden;
  }

  .thTop {
    background: #dee2e6;
  }

  .th2{
    width: 25%;
    position: sticky;
    left: -1px;
    z-index: 1;
  }

  .thTop:nth-child(5) {
    z-index: 101;
  }

  td {
    padding: 5px !important;
  }

  .basic-color {
    background-color: white;
  }

  .fa-analytics:hover {
    cursor: pointer;
  }

  .input-date {
    width: 100% !important;
  }

  .tooltip {
    display: block !important;
    z-index: 10000;

    &.popover {
      $color: #f9f9f9;

      .popover-inner {
        background: $color;
        color: black;
        padding: 24px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, .1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }
  }

  .tooltip .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
  }

  .tooltip.popover .popover-arrow {
    /* border-color: #f9f9f9; */
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }

  .edit-btn {
    margin-left: 1rem;
    align-self: center;
  }

  .edit-mode {
    background-color: #e8e9fd;
  }

  .budgets-cell {
    width: 7rem;
  }

  .campaign-status {
    padding: .3rem 0.4rem;
    border-radius: 100px;
    font-weight: 600;
    font-size: 11px;
    text-transform: capitalize;
    display: inline-block;
  }

  .campaign-status:hover, .sorterColumn:hover {
    cursor: pointer;
  }

  .running {
    background-color: #27c414;
    color: #fff;
    line-height: normal;
    border: 1px solid #27c414;
  }

  .creating {
    font-size: 20px;
  }

  .create_error {
    font-size: 13px;
    color: red;
  }

  .pending {
    font-size: 20px;
  }

  .mismatch:hover {
    cursor: default;
  }

  .mismatch {
    background-color: #ff851d;
    color: #fff;
    line-height: normal;
    border: 1px solid #ff851d;
  }

  .terminated
  {
    background-color: #ff0000;
    color: #fff;
    line-height: normal;
    border: 1px solid #ff0000;
  }

  .paused,
  .inactive{
    background-color: #dee2e6;
    color: #fff;
    line-height: normal;
    border: 1px solid #dee2e6;
  }

  .chart-td
  {
    cursor: pointer;
  }

  .fa-facebook-f {
    color: #2024c2;
    width: 15px;
  }

  .fa-twitter {
    color: #49d9f9;
  }

  .fa-pinterest
  {
    color: #d60c0c;
  }

  .fa-snapchat-ghost {
    color: #ffe529;
  }

</style>
<style scoped>

  .multi-adsets {
    color: #000;
    cursor: pointer;
    font-size: 1rem;
  }

  .campaigns-container {
    overflow: auto;
    height: 1000px;
  }

  table {
    text-align: left;
  }

  .revenu-btn,
  .revenu-btn:hover {
    border: 0;
    font-size: 13px;
    background: none;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    color: #000;
    font-weight: inherit;
  }

  thead tr:first-of-type {
    font-weight: bold;
    border: 0;
    background-color: #f9f9f9;
  }

  thead th {
    border-right: 1px solid #d7d8d8;
  }

  thead tr:nth-child(2) {
    background-color: #dee2e6;
  }

  thead tr:first-of-type>th:first-of-type,
  thead tr:first-of-type>th:nth-of-type(2),
  thead tr:first-of-type>th:nth-of-type(3) {
    border: 0;
  }

  .hash-code {
    font-size: 10px;
    color: grey;
  }

  .campaign-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /*.campaign-name div {
    font-size: 14px;
    font-weight: 500;
  }
  */
  .campaign-name .hash-code {
    font-weight: normal;
    font-size: 10px;
  }

  .campaign-name .fab {
    font-size: 1.2rem;
  }

  .fa-analytics {
    font-size: 1rem;
  }

  .fa-sticky-note {
    background-color: white;
  }

  i.fa-hat-cowboy {
    color: #ff17d8;
  }

  .export-btn {
    margin-left: auto;
  }

  .export-btn .round-btn {
    color: #2e2e2e;
    border: 2px solid #2e2e2e;
    border-radius: 30px;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
  }

  #campaigns td {
    vertical-align: middle;
  }

  .input-group input,
  .input-group span {
    font-size: 13px;
  }

  .input-group {
    min-width: 160px;
    flex-wrap: nowrap !important;
  }

  .input-group .form-control {
    width: 60px;
  }

  .alert-fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    border-radius: 0px;
    margin-top: 4rem;
    padding: 1rem;
    text-align: center;
  }

  .complement {
    background-color: #fffdef;
  }

  .fa-snooze {
    color: red;
  }

  .rejected {
    font-size: 20px;
    color: red
  }

  .fa-baby {
    margin-right: 1rem;
  }

  .bookmarked {
    background: #fffed4;
  }

  .campaign-name-title
  {
    display: flex;
    align-items: center;
  }

  .campaign-name-title-text
  {
    font-weight: bold;
    font-size: 14px;
  }

  .campaign-name-icons i {
    margin: 0 2px;
  }

  @media(max-width: 767px) {

    .campaign-name-icons {
      margin-top: 1rem;
      flex-wrap: wrap;
    }

    .campaign-name-icons i {
      margin: 0.1rem;
    }

    #dropdown-dropright {
      margin: -0.3rem;
    }

    .table td {
      padding: 0.4rem;
    }

    .campaign-name-title {
      font-size: 11px !important;
    }

    table {
      display: block;
      overflow-x: scroll;
      position: sticky;
      left: 0;
      right: 0;
      height: 80vh;
    }

    .sticky .th{
      top: -1px;
    }

  }

  @media(max-width: 420px) {
    .vs-card--content {
      padding: 5px; 
    }
  }


  .campaign-name-icons {
    display: flex;
  }

  .campaign-row:hover, .campaign-row:hover > td
  {
    background: #eeeeee !important;
  }

  .bottom-filter
  {
    padding-bottom: 1rem;
  }

  .text-bold
  {
    font-weight: 800;
  }

  .revenu-btn,
  .revenu-btn:hover {
    border: 0;
    font-size: 13px;
    background: none;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    color: #000;
    font-weight: inherit;
  }

  .taboola-icon
  {
    height: 17px;
  }

  .locked-campaign
  {
    background: #dee2e6;
  }

  .budget_type
  {
    color: grey;
    font-size: 10px;
  }

</style>
