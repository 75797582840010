<template>
  <vs-card class="articles">
    <b-alert @dismissed="dismissCountDown = 0" :show="dismissCountDown" @dismiss-count-down="countDownChanged"
      :variant="alertType" class="alert-fixed" dismissible>
      {{ alertError }}
    </b-alert>
    <div id="panel">
      <div class="column">
        <div class="field">
          <label class="short" for="period">Period</label>
          <date-range-picker v-model="perfRange" :options="perfRangeOptions" :key="timezone" />
        </div>
        <div class="field">
          <label class="short" for="status">Status</label>
          <select v-model="campaignStatus">
            <option :value="status" :key="status" v-for="status in campaignStatuses">
              {{ status }}
            </option>
          </select>
        </div>
      </div>
      <div class="column">
        <h3>Time Zone</h3>
        <div class="time-zone-buttons">
          <div @click="changeTimezone(timeObject.value)" v-for="timeObject in availableTimezones"
            :key="timeObject.label" class="toggle-button" :class="`${timezone == timeObject.value ? 'active' : ''}`">{{
                timeObject.label
            }}</div>
        </div>
      </div>
      <div class="column source">
        <h3>Source</h3>
        <div class="source-buttons">
          <div>
            <div @click="updatePlatformFilter('all')" class="toggle-button"
              :class="`${allPlatformsSelected ? 'active' : ''}`">All</div>
            <div @click="updatePlatformFilter('yahoo')" class="toggle-button"
              :style="`background-color: ${isPlatformSelected('yahoo') ? getPlatformColor('yahoo') : '#ffffff'}`">
              <img v-show="isPlatformSelected('yahoo')" src="@/assets/images/icons/icon_white_yahoo.svg" />
              <img v-show="!isPlatformSelected('yahoo')" src="@/assets/images/icons/icon_colored_yahoo.svg" />
            </div>
          </div>
          <div>
            <div @click="updatePlatformFilter('facebook')" class="toggle-button"
              :style="`background-color: ${isPlatformSelected('facebook') ? getPlatformColor('facebook') : '#ffffff'}`">
              <img v-show="isPlatformSelected('facebook')" src="@/assets/images/icons/icon_white_facebook.svg" />
              <img v-show="!isPlatformSelected('facebook')" src="@/assets/images/icons/icon_colored_facebook.svg" />
            </div>
            <div @click="updatePlatformFilter('taboola')" class="toggle-button"
              :style="`background-color: ${isPlatformSelected('taboola') ? getPlatformColor('taboola') : '#ffffff'}`">
              <img v-show="isPlatformSelected('taboola')" src="@/assets/images/icons/icon_white_taboola.svg" />
              <img v-show="!isPlatformSelected('taboola')" src="@/assets/images/icons/icon_colored_taboola.svg" />
            </div>
          </div>
          <div>
            <div @click="updatePlatformFilter('twitter')" class="toggle-button"
              :style="`background-color: ${isPlatformSelected('twitter') ? getPlatformColor('twitter') : '#ffffff'}`">
              <img v-show="isPlatformSelected('twitter')" src="@/assets/images/icons/icon_white_twitter.svg" />
              <img v-show="!isPlatformSelected('twitter')" src="@/assets/images/icons/icon_colored_twitter.svg" />
            </div>
            <div @click="updatePlatformFilter('pinterest')" class="toggle-button"
              :style="`background-color: ${isPlatformSelected('pinterest') ? getPlatformColor('pinterest') : '#ffffff'}`">
              <img v-show="isPlatformSelected('pinterest')" src="@/assets/images/icons/icon_white_pinterest.svg" />
              <img v-show="!isPlatformSelected('pinterest')" src="@/assets/images/icons/icon_colored_pinterest.svg" />
            </div>
          </div>
          <div>
            <div @click="updatePlatformFilter('tiktok')" class="toggle-button"
              :style="`background-color: ${isPlatformSelected('tiktok') ? getPlatformColor('tiktok') : '#ffffff'}`">
              <img v-show="isPlatformSelected('tiktok')" src="@/assets/images/icons/icon_white_tiktok.svg" />
              <img v-show="!isPlatformSelected('tiktok')" src="@/assets/images/icons/icon_colored_tiktok.svg" />
            </div>
            <div @click="updatePlatformFilter('snapchat')" class="toggle-button"
              :style="`background-color: ${isPlatformSelected('snapchat') ? getPlatformColor('snapchat') : '#ffffff'}`">
              <img v-show="isPlatformSelected('snapchat')" src="@/assets/images/icons/icon_white_snapchat.svg" />
              <img v-show="!isPlatformSelected('snapchat')" src="@/assets/images/icons/icon_colored_snapchat.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="medium" for="assignee">Assignee</label>
          <multiselect class="input-field" :custom-label="assigneesFullName" v-model="filter_assignee" track-by="id"
            :options="assignees" :multiple="true" placeholder="All" selectLabel="" deselectLabel=""
            :close-on-select="false" :clear-on-select="false" :preserve-search="true">
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} assignees
                selected</span>
            </template>
          </multiselect>
        </div>
        <div class="field">
          <label class="medium" for="publisher">Publisher</label>
          <multiselect class="input-field" label="name" v-model="publisher_id" track-by="id" :options="publishers"
            :multiple="true" placeholder="All" selectLabel="" deselectLabel="" :close-on-select="false"
            :clear-on-select="false" :preserve-search="true">
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} publishers
                selected</span>
            </template>
          </multiselect>
        </div>
        <div class="field">
          <label class="medium" for="locale">Language</label>
          <multiselect class="input-field" label="name" v-model="filter_locale" track-by="code" :options="locales"
            :multiple="true" placeholder="All" selectLabel="" deselectLabel="" :close-on-select="false"
            :clear-on-select="false" :preserve-search="true">
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} languages
                selected</span>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="long" for="author">Author</label>
          <multiselect class="input-field long" label="name" :custom-label="assigneesFullName" v-model="filter_author"
            track-by="name" :options="authors" :multiple="true" placeholder="All" selectLabel="" deselectLabel=""
            :close-on-select="false" :clear-on-select="false" :preserve-search="true">
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} authors
                selected</span>
            </template>
          </multiselect>
        </div>
        <div class="field">
          <label class="long" for="adAccount">Ad Account</label>
          <multiselect class="input-field long small" label="name" v-model="filter_ad_account" track-by="id"
            :options="computedAdAccounts" :multiple="true" placeholder="All" selectLabel="" deselectLabel=""
            :close-on-select="false" :clear-on-select="false" :preserve-search="true">
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} accounts
                selected</span>
            </template>
          </multiselect>
        </div>
        <div class="field">
          <label class="long" for="vp">Device</label>
          <multiselect class="input-field long" label="text" v-model="filter_device" track-by="value" :options="devices"
            :multiple="true" placeholder="All" selectLabel="" deselectLabel="" :close-on-select="false"
            :clear-on-select="false" :preserve-search="true">
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} players
                selected</span>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="column">
        <div class="field text">
          <input v-on:keyup.enter="refreshTextField" type="text" placeholder="Article ID" autocomplete="off"
            v-model="filter_utm_medium" />
        </div>
      </div>
    </div>
    <div id="panel-controller" class="align-items-left">
      <div class="d-flex flex-wrap">
        <div @click="refresh(true)" class="button green">Apply Filter</div>
        <div @click="clearFilters()" class="button red">Clear Filters</div>
        <div @click="refresh(false, true)" class="button red"><i class="fas fa-sync"
            :class="isRefreshing ? 'fa-spin' : ''"></i></div>
      </div>
    </div>
    <div v-if="showRefreshWarning" class="alert alert-warning">
      <i aria-hidden="true" class="fa fa-exclamation-circle"></i>
      There are new updates for the selected filters, refresh to get the latest data
    </div>
    <div class="">
      <div class="articles-container">
        <table v-if="tableReloaded" id="articles" class="tablemobile text-center table-bordered table">
          <thead class="sticky">
            <tr v-if="Object.keys(totals).length > 0">
              <th colspan="4">
              </th>
              <template v-for="col in selectedColumnsData">
                <th :key="col.name">
                  <template v-if="col.name === 'rpm'">
                    <b-button @click="loadRpms('all')" :id="`popover-rpm-total`" variant="primary" class="revenue-btn">
                      {{
                          col.formatTotals(totals)
                      }}</b-button>
                    <b-popover :target="`popover-rpm-total`" triggers="hover focus" placement="leftbottom" delay="500" custom-class="popover-custom-body"
                      offset="500">
                      <h6>RPM</h6>
                      <table>
                        <tbody>
                          <tr :key="key + 'total'" v-for="(value, key) in rpms">
                            <td>{{ key }}</td>
                            <td>{{ numeral(value).format('$0,0.000') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-popover>
                  </template>

                  <template v-else-if="col.name === 'revenue'">
                    <b-button @click="loadRevenues('all')" :id="`popover-rev-total`" variant="primary"
                      class="revenue-btn"> {{ col.formatTotals(totals) }} </b-button>
                    <b-popover :target="`popover-rev-total`" triggers="hover focus" placement="leftbottom" delay="500" custom-class="popover-custom-body"
                      offset="500">
                      <h6>Revenue</h6>
                      <table>
                        <tbody>
                          <tr :key="key + 'total'" v-for="(value, key) in revenues">
                            <td>{{ key }}</td>
                            <td>{{ numeral(value).format('$0,0.000') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-popover>
                  </template>

                  <div v-else class="resize-cell">
                    <span :class="col.cssClass ? col.cssClass(totals) : ''">{{ col.formatTotals(totals) }}</span>
                  </div>
                </th>
              </template>
              <th>{{ totals.running_campaigns }}</th>
              <th>{{ totals.scheduled_campaigns }}</th>
              <th>-</th>
            </tr>
            <tr>
              <th class="th text-center">Site</th>
              <th class="th">Article ID</th>
              <th class="th thTop th2 text-left">Article / Campaign</th>
              <th class="th">Daily Budget <i v-if="sort_by == 'daily_budget'"
                  :class="'fas fa-sort-' + (sort_dir == 'desc' ? 'down' : 'up')"></i></th>

              <template v-for="col in selectedColumnsData">
                <th :key="col.name" :class="`th${col.isMetricsAgg ? ' sorterColumn' : ''}`"
                  @click="changeSort(col.name)">{{ col.header }} <i v-if="col.isMetricsAgg && sort_by == col.name"
                    :class="'fas fa-sort-' + (sort_dir == 'desc' ? 'down' : 'up')"></i></th>
              </template>
              <th class="th">Running Campaigns</th>
              <th class="th">Scheduled</th>
              <th class="th">Ad Acounts</th>
            </tr>
          </thead>
          <template v-if="!articles_data && !campaigns_error">
            <tbody>
              <tr>
                <td colspan="29">
                  <clip-loader color="#20313b" />
                </td>
              </tr>
            </tbody>
          </template>

          <template v-else-if="articles_data && articles_data.length == 0">
            <tbody>
              <tr>
                <td colspan="29">No articles</td>
              </tr>
            </tbody>
          </template>
          <template v-else>
            <tbody v-for="content in articles_data" :key="content.id" :ref="`article-${content.id}`">
              <tr :key="content.id" :class="colorPick(content)">
                <td class="text-center text-uppercase site-code" v-if="content.title"
                  :style="`color: ${publisherObject(content).color}`">{{ publisherObject(content).code }}</td>
                <td>
                  <span v-if="content.title" class="text-center">{{ content.id }}</span>
                  <span v-else class="btn badge badge-secondary">No Data</span>
                </td>
                <td class="th2 text-left" :class="colorPick(content)">
                  <div class="article-name" v-if="content">
                    <div>
                      <div v-if="content.title" @click="getArticleCampaigns(content.id)" :id="content.id"
                        :class="`text-left ${showCampaignsRow === content.id ? '' : 'ellipsis'}`">
                        <span class="article-name-title-text">{{ content.title }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="((budgets[content.id] || {}).daily_budget !== undefined)"
                    class=" budgets-cell input-group">
                    <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                    <input type="text" :disabled="true" min="0.00" class="form-control"
                      v-model="budgets[content.id].daily_budget" />
                  </div>
                </td>
                <template v-for="col in selectedColumnsData">
                  <td :key="col.name" :class="col.cssClass ? col.cssClass(content) : ''">
                    <template v-if="col.name == 'units'">
                      <UnitsTooltip :timezone="timezone" :start="selectedDate.start" :end="selectedDate.end"
                        :content="col.format(content)" />
                    </template>
                    <template v-else-if="col.name == 'rpm'">
                      <b-button @click="loadRpms(content.id)" :id="`popover-rpm-${content.id}`" variant="primary"
                        class="revenue-btn">{{ col.format(content) }}
                      </b-button>
                      <b-popover :target="`popover-rpm-${content.id}`" triggers="hover focus" custom-class="popover-custom-body">
                        <table>
                          <tbody>
                            <tr :key="key + content.id" v-for="(content, key) in rpms">
                              <td>{{ key }}</td>
                              <td>{{ numeral(content).format('$0,0.000') }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </b-popover>
                    </template>
                    <template v-else-if="col.name == 'revenue'">
                      <b-button @click="loadRevenues(content.id)" :id="`popover-${content.id}`" variant="primary"
                        class="revenue-btn">{{ col.format(content) }}</b-button>
                      <b-popover :target="`popover-${content.id}`" triggers="hover focus" custom-class="popover-custom-body">
                        <table>
                          <tbody>
                            <tr :key="key + content.id" v-for="(content, key) in revenues">
                              <td>{{ key }}</td>
                              <td>{{ numeral(content).format('$0,0.000') }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </b-popover>
                    </template>

                    <span v-else>{{ col.format(content) }}</span>
                    <template v-if="col.customElement">
                      <span :class="col.customElement.cssClass ? col.customElement.cssClass(content) : ''"> {{
                          col.customElement.format(content)
                      }}</span>
                    </template>
                  </td>
                </template>
                <td>
                  <div v-if="content.running_camp_count" class="text-center">
                    <span>{{ content.running_camp_count }}</span>
                  </div>
                </td>
                <td>
                  <div v-if="content.scheduled_camp_count" class="text-center">
                    <span>{{ content.scheduled_camp_count }}</span>
                  </div>
                </td>
                <td>
                  <div v-if="content.ad_account_count" class="text-center">
                    <span>{{ content.ad_account_count }}</span>
                  </div>
                </td>
              </tr>
              <template v-if="content.id == showCampaignsRow">
                <template v-if="campaigns.length > 0">
                  <CampaignsRow v-for="campaign in campaigns" :key="campaign.id" :campaign="campaign"
                    :tiny_perfs="tiny_perfs" :selectedColumnsData="selectedColumnsData"
                    :getFilteredParams="getFilteredParams" :perfRangeOptions="perfRangeOptions"
                    :notifications="notifications" />
                </template>
                <tr v-else-if="campaigns.length == 0" :key="content.id">
                  <td colspan="2"></td>
                    <div class="my-4 flex flex-col items-center">
                      <BIconExclamationCircle font-scale="2" class="mb-1" /> No Running Campaigns.
                    </div>
                </tr>
                <tr v-else :key="content.id">
                  <td colspan="2"></td>
                  <div class="my-4 flex flex-col items-center">
                    <b-spinner /> Loading...
                  </div>
                </tr>
              </template>
            </tbody>
          </template>
        </table>
      </div>
    </div>
    <b-pagination pills v-model="currentPage" align="center" :total-rows="totalRows" :per-page="limit"></b-pagination>
    <div class="text-center">Articles per page: {{ this.limit }} - Total Articles: {{ totalRows }}</div>
  </vs-card>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from 'moment-timezone';
import numeral from 'numeral';
import { BIconExclamationCircle } from 'bootstrap-vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { OPTIONS, UTILS } from "../utils/campaign_form.js";
import { LOGGER } from '../utils/logger/logger.js'
import { STORAGE, COLUMNS } from '../utils/settings/columns.js';
import UnitsTooltip from './overview/UnitsTooltip.vue';
import CampaignsRow from "./overview/CampaignsRow.vue";
import { AVAILABLE_TIMEZONES, datePickerRanges } from "../utils/values.js";
import { PUBLISHERS } from "../utils/product_entities.js";
import platformOptions from "../utils/platform_options";

export default {
  name: 'ArticlesPage',
  props: ['tableReloaded'],
  data: function () {
    return {
      defaultFilters: {
        filter_device: [],
        filter_locale: [],
        filter_vp: [],
        filter_platform: [],
        filter_utm_medium: '',
        filter_assignee: [],
        filter_author: [],
        filter_ad_account: [],
        adv_filters_metrics: [],
        campaignStatus: 'All',
        perfRange: [moment().startOf('day'), moment().endOf('day')],
        publisher_id: [],
        learningMode: 'all'
      },
      devices: [
        { value: "ios", text: "IOS" },
        { value: "android", text: "Andriod" },
        { value: "pc", text: "Desktop" },
        { value: "mobile", text: "Mobile" },
        { value: "tablet", text: "Tablet" }
      ],
      limit: this.$isProd ? 30 : 30,
      currentPage: 1,
      totalRows: 0,
      campaigns_error: '',
      refreshTimeout: 0,
      campaignStatuses: ['All', 'paused', 'running', 'terminated', 'in_review', 'mismatch', 'creating', 'create_error'],
      campaignStatus: 'All',
      adv_filters_metrics: [],
      filter_device: [],
      filter_locale: [],
      filter_vp: [],
      filter_platform: [],
      filter_utm_medium: '',
      filter_assignee: [],
      filter_author: [],
      filter_ad_account: [],
      learningMode: 'all',
      locales: [],
      assignees: [],
      authors: [],
      isRefreshing: false,
      dismissCountDown: 0,
      alertType: 'danger',
      alertError: '',
      totals: {},
      notifications: {},
      numeral,
      sort_by: null,
      sort_dir: 'desc',
      publisher_id: [],
      publishers: PUBLISHERS,
      moment,
      campaigns: null,
      showCampaignsRow: null,
      articles: null,
      tiny_perfs: {},
      tiny_perfs_charts: {},
      revenues: {},
      rpms: {},
      units: {},
      availableTimezones: AVAILABLE_TIMEZONES,
      timezone: localStorage.getItem("tz") || 'UTC',
      datePickerRanges: datePickerRanges,
      perfRange: [moment().startOf('day'), moment().endOf('day')],
      edits: {},
      perfRangeOptions: {
        alwaysShowCalendars: true,
        maxSpan: { "days": 14 },
        maxDate: moment().add(1, 'days'),
        autoApply: true,
        ranges: datePickerRanges(),
      },
      budgets: {},
      realtime_users: {},
      selectedDate: {
        start: moment().startOf('day'),
        end: moment().endOf('day')
      },
      isResetting: false,
      autoRefresh: true,
      disableAutoRefresh: false,
      selectedColumns: [],
      adAccounts: [],
      realTimeUsers: null,
      showRefreshWarning: false
    }
  },
  watch: {
    autoRefresh(v) {
      v ? this.schedRefresh() : this.cancelRefresh();
    },
    tableReloaded() {
      this.selectedColumns = STORAGE.get('selectedColumnsArticles');
    },

    currentPage: function () {
      this.refresh(false, true);
    },
    perfRange: function (n) {
      this.selectedDate.start = moment(n[0], 'DD/MM/YYYY').startOf('day');
      this.selectedDate.end = moment(n[1], 'DD/MM/YYYY').endOf('day');
      this.disableAutoRefresh = (!moment().startOf('day').isSame(this.selectedDate.start) || !moment().endOf('day').isSame(this.selectedDate.end));
      if (this.disableAutoRefresh) this.autoRefresh = false;
    },
  },
  mounted() {
    LOGGER.initSession('Articles', this.$http, this.host)
    moment.tz.setDefault(this.timezone);
    this.selectedColumns = STORAGE.get('selectedColumnsArticles');
    this.addLocales();
    this.resetSortBy();
    this.unloadFiltersFromURL();
    this.schedRefresh();

    this.loadAdAccounts();

    this.$eventHub.$on("resetBadges", (data) => {
      this.notifications[data.campaign.id] = null;
      this.$forceUpdate();
    })

    this.$eventHub.$on("syncNoteIcons", (data) => {
      this.articles_data[data.campaignName].has_note = data.hasNote;
      this.$forceUpdate();
    })

    this.$root.$on('bv::popover::show', bvEventObj => {
      bvEventObj.target.click();
    })

    this.$http.get(`${this.host}/users`, { params: { 'filter': 'assignee' } }).then(res => {
      this.assignees = res.data.users
      this.authors = res.data.authors
    });

    this.$cable.subscriptions.create({
      channel: 'AnalyticsChannel',
    }, {
      received: this.handlePayloadReceived,
      connected: function () {
        this.perform("hello", {})
      }
    });
  },
  computed: {
    localeCodes: function () {
      return this.filter_locale.map(l => l.code).join(',')
    },
    allPlatformsSelected() {
      return this.filter_platform.length == 0;
    },
    computedAdAccounts: function () {
      return this.adAccounts.filter(ad => {
        let isValid = true;

        if (!this.allPlatformsSelected)
          isValid = this.filter_platform.includes(ad.platform)

        if (this.publisher_id.length > 0)
          isValid = isValid && this.publisher_id.filter(pub => pub.id == ad.publisher_id).length > 0;

        return isValid;
      });
    },
    selectedColumnsData: function () {
      return COLUMNS.getSelectedData(this.selectedColumns)
    },
    articles_data: function () {
      return this.articles;
    },
  },
  methods: {
    assigneesFullName({ first_name, last_name }) {
      return `${first_name} ${last_name}`;
    },
    isPlatformSelected(platform) {
      return !this.allPlatformsSelected && this.filter_platform.includes(platform)
    },
    updatePlatformFilter(platform) {
      if (platform == 'all') {
        this.filter_platform = [];
      } else {
        this.filter_platform.includes(platform) ? this.filter_platform = this.filter_platform.filter(plat => plat != platform) : this.filter_platform.push(platform);
      }
    },
    refreshTextField(_e) {
      if (!this.isResetting) this.refresh(true);
    },
    getPlatformColor(platform) {
      return this.platformOptions(platform)?.color || 'facebook';
    },
    addLocales() {
      for (let platform of Object.keys(platformOptions)) {
        for (let locale of JSON.parse(JSON.stringify(platformOptions[platform].locales))) {
          let exist = this.locales.filter(x => locale.name == x.name)[0];

          if (exist)
            exist.code += `,${locale.code}`;
          else
            this.locales.push(locale);
        }
      }
    },
    changeTimezone(n) {
      localStorage.setItem("tz", n);
      this.timezone = n;
      moment.tz.setDefault(n);

      let start = moment(this.selectedDate.start, "DD/MM/YYYY").tz(n, true).startOf("day");
      let end = moment(this.selectedDate.end, "DD/MM/YYYY").tz(n, true).endOf("day");
      this.$set(this, "selectedDate", { start, end });

      this.$set(this.perfRangeOptions, "ranges", this.datePickerRanges());
      this.$set(this.perfRangeOptions, "startDate", start);
      this.$set(this.perfRangeOptions, "endDate", end);
      this.$set(this, "perfRange", [start, end]);
    },
    loadAdAccounts() {
      this.$http.get(`${this.host}/ad_accounts`).then(res => {
        this.adAccounts = res.data.ad_accounts;
      })
    },
    resetSortBy() {
      const DEFAULT_SORT_BY = 'clicks'
      const { selectedColumns } = this

      if ((selectedColumns || []).includes(DEFAULT_SORT_BY)) {
        this.sort_by = DEFAULT_SORT_BY
      } else {
        this.sort_by = selectedColumns.find(COLUMNS.isColMetricsAgg)
      }
    },
    unloadFiltersFromURL() {
      const COLLAPSED_FILTERS = ['filter_locale', 'filter_device', 'filter_ad_account', 'campaignName', 'adv_filters_metrics']
      let urlFilters = Object.assign({}, this.$route.query)
      let showCollapsed = false

      Object.keys(urlFilters).forEach(filter => {

        if (!UTILS.isEqual(urlFilters[filter], this[filter])) {

          this[filter] = JSON.parse(urlFilters[filter])

          if (COLLAPSED_FILTERS.includes(filter)) showCollapsed = true
        }

      })
      this.updateDatePickersDisplay()

      this.refresh(true)
    },
    saveFiltersToURL() {
      let filters = {}

      Object.keys(this.defaultFilters).forEach(filter => {
        if (!UTILS.isEqual(this[filter], this.defaultFilters[filter])) {
          filters[filter] = JSON.stringify(this[filter])
        }
      })

      let q = Object.keys(filters).length > 0 ? `?${new URLSearchParams(filters).toString()}` : ''
      history.pushState({}, '', `#${this.$route.path}${q}`);
    },
    clearFilters() {
      this.isResetting = true;
      for (let filter in this.defaultFilters)
        this[filter] = Array.isArray(this.defaultFilters[filter])
          ? [...this.defaultFilters[filter]]
          : this.defaultFilters[filter];

      this.clearAdvFilter();
      this.updateDatePickersDisplay();

      this.$nextTick(() => {
        this.isResetting = false;
        this.refresh(true);
      });
    },
    updateDatePickersDisplay() {
      Object.keys(this.defaultFilters).filter(f => f.includes('Range')).forEach(range => {
        let clonedRange = UTILS.clone(this[range])

        if (this[`${range}Options`].singleDatePicker) {
          clonedRange = Array(2).fill(clonedRange)
        }

        this.$set(this[`${range}Options`], 'startDate', clonedRange[0])
        this.$set(this[`${range}Options`], 'endDate', clonedRange[1])
      })
    },
    handlePayloadReceived(payload) {
      if (!payload)
        return;

      this.realTimeUsers = payload.realtime;
      this.updateRealTimeUsers()
    },
    updateRealTimeUsers() {
      if (this.campaigns && this.realTimeUsers) {
        this.campaigns.forEach(camp => {
          if (this.realTimeUsers[camp.id])
            this.$set(camp, 'realtimeUsers', this.realTimeUsers[camp.id]);
        })
      }
      this.totals['realtimeUsers'] = Object.values(this.realTimeUsers || {}).reduce((acc, val) => acc + val, 0)
    },
    getTinyPeftMood(campaign_id) {
      if (!this.tiny_perfs[campaign_id])
        return;

      let perf = this.tiny_perfs[campaign_id];
      let len = perf.length;

      if (perf[len - 2] > perf[len - 1])
        return '#dc3545';

      return '#27c414'
    },
    clearAdvFilter() {
      this.adv_filters_metrics = []
    },
    publisherObject(content) {
      return PUBLISHERS.find(pub => {
        return pub.url.indexOf(content.publisher) > -1;
      });
    },
    colorPick(content) {
      if (moment(new Date()).diff(content.user_changed_at, 'minutes') <= 180)
        return 'bookmarked campaign-row';
      else if (content.complement)
        return 'complement campaign-row'
      else if (content.bookmarked)
        return 'bookmarked campaign-row'
      else
        return 'basic-color campaign-row'
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    isTinyPerfEmpty(campaign_id) {
      return this.tiny_perfs[campaign_id].every(val => val === 0)
    },
    destroyTinyPerf(campaign_id) {
      if (this.tiny_perfs_charts[campaign_id]) {
        this.tiny_perfs_charts[campaign_id].destroy();
        this.tiny_perfs_charts[campaign_id] = null;
      }
    },
    displayTinyPerf(campaign_id) {
      if (!this.tiny_perfs[campaign_id])
        return;

      if (this.tiny_perfs_charts[campaign_id]) {
        this.tiny_perfs_charts[campaign_id].data.datasets[0].data = this.tiny_perfs[campaign_id];
        this.tiny_perfs_charts[campaign_id].update()
        return;
      }

      let sl = document.getElementById(`sparkline-${campaign_id}`)

      if (!sl)
        return;

      sl = sl.getContext('2d');

      if (this.isTinyPerfEmpty(campaign_id))
        return;

      // eslint-disable-next-line
      let chart = new Chart(sl, {
        type: 'line',
        data: {
          labels: Array(this.tiny_perfs[campaign_id].length).fill(0),
          datasets: [
            {
              data: this.tiny_perfs[campaign_id]
            }
          ]
        },
        options: {
          animation: {
            duration: 0,
          },
          hover: {
            animationDuration: 0,
          },
          responsiveAnimationDuration: 0,
          responsive: false,
          legend: {
            display: false
          },
          elements: {
            line: {
              borderColor: this.getTinyPeftMood(campaign_id),
              borderWidth: 2,
              backgroundColor: this.getTinyPeftMood(campaign_id) + "5f"
            },
            point: {
              radius: 0
            }
          },
          tooltips: {
            enabled: false
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  min: Math.min(...this.tiny_perfs[campaign_id], -0.05),
                  max: Math.max(...this.tiny_perfs[campaign_id], 0.05),
                  display: false,
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                display: false,
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false
                }
              }
            ]
          }
        }
      });

      this.tiny_perfs_charts[campaign_id] = chart;
    },
    getFilteredParams() {
      return {
        sort_by: this.sort_by,
        sort_dir: this.sort_dir,
        campaign_name: this.campaignName,
        device: this.filter_device.map(dev => dev.value),
        locale: this.localeCodes,
        platform: this.filter_platform,
        utm_medium: this.filter_utm_medium,
        assignee: this.filter_assignee.map(ass => ass.id),
        author: this.filter_author.map(author => author.id),
        vp: this.filter_vp.map(vp => vp.value),
        filters: this.adv_filters_metrics,
        campaign_status: this.campaignStatus.toLowerCase(),
        ad_account: this.filter_ad_account.map(acc => acc.id),
        start: this.selectedDate.start,
        end: this.selectedDate.end,
        publisher_id: this.publisher_id.map(pub => pub.id),
        user_id: this.$user.id,
        columns: this.selectedColumns.filter(COLUMNS.isColMetricsAgg),
      }
    },
    getArticleCampaigns(utm_medium) {
      if (!utm_medium)
        return;

      this.campaigns = {};
      this.showCampaignsRow = (this.showCampaignsRow === utm_medium) ? null : utm_medium;
      if (!this.showCampaignsRow) return

      this.$http.get(`${this.host}/campaigns`, {
        params: {
          offset: (this.currentPage - 1) * this.limit,
          limit: this.limit,
          columns: this.selectedColumns.filter(COLUMNS.isColMetricsAgg),
          ...this.getFilteredParams(),
          utm_medium,
          campaign_status: 'running'
        }
      }).then(res => {
        this.campaigns = res.data.campaigns;
        this.notifications = res.data.notifications
        const campaign_ids = res.data.campaigns.map(c => c.id);
        this.$http.get(`${this.host}/campaigns/tiny_perf`, { params: { campaigns: campaign_ids } }).then(res => {
          this.tiny_perfs = res.data.perfs;
          this.$nextTick(() => {
            for (var k in this.tiny_perfs) {
              this.destroyTinyPerf(parseInt(k))
              this.displayTinyPerf(parseInt(k))
            }
          })
        })
      })
    },
    loadRevenues: function (article_id) {
      if (!article_id)
        return;

      this.revenues = {};
      this.$http.get(`${this.host}/articles/${article_id}/revenues`, {
        params: this.getFilteredParams()
      }).then(res => {
        this.revenues = res.data.revenues
      })
    },
    loadRpms: function (article_id) {
      if (!article_id)
        return;

      this.rpms = {};
      this.$http.get(`${this.host}/articles/${article_id}/rpms`, {
        params: this.getFilteredParams()
      }).then(res => {
        this.rpms = res.data.rpms
      })
    },
    schedRefresh() {
      if (this.$isProd && this.autoRefresh) {
        if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 30000)
      }
    },
    cancelRefresh() {
      if (this.$isProd) {
        if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
      }
    },
    changeSort: function (metric) {
      if (!COLUMNS.isColMetricsAgg(metric)) return;

      if (this.sort_by == metric && this.sort_dir == 'desc')
        this.sort_dir = 'asc';
      else
        this.sort_dir = 'desc';

      this.sort_by = metric;
      this.refresh(true);
    },
    refresh: function (reset, force) {

      if (force) {
        this.edits = {}
      }

      if (Object.keys(this.edits).length > 0) {
        this.schedRefresh();
        return;
      }

      if (reset) {
        this.campaigns = null;
        this.currentPage = 1;
      }

      let learningMode = null
      if (this.learningMode != 'all')
        learningMode = this.learningMode == 'true'

      this.saveFiltersToURL();
      this.campaigns_error = '';
      this.isRefreshing = true;
      let _this = this;
      this.$http.get(`${this.host}/campaigns`, {
        params: {
          offset: (this.currentPage - 1) * this.limit,
          limit: this.limit,
          learning_mode: learningMode,
          columns: this.selectedColumns.filter(COLUMNS.isColMetricsAgg),
          ...this.getFilteredParams(),
          entity: "utm_medium",
        },
      }).then(res => {

        this.isRefreshing = false;

        if (Object.keys(this.edits).length > 0)
          return;

        this.articles = res.data.campaigns;
        this.totals = res.data.totals;
        this.notifications = res.data.notifications
        if (Object.keys(this.edits).length === 0)
          this.budgets = this.articles.reduce((budgets, article) =>
            ({ ...budgets, [article.id]: { daily_budget: article.daily_budget } }),
            {}
          )
        this.offset += this.articles.length;
        this.totalRows = res.data.count;

        this.$set(this, 'showRefreshWarning', false);
        let campaign_ids = this.articles.map(c => c['id'])

        this.$http.get(`${this.host}/campaigns/tiny_perf`, { params: { campaigns: campaign_ids } }).then(res => {
          this.tiny_perfs = res.data.perfs;
          this.$nextTick(() => {
            for (var k in this.tiny_perfs) {
              this.destroyTinyPerf(parseInt(k))
              this.displayTinyPerf(parseInt(k))
            }
          })
        }).finally(() => {

        });
      }).catch(err => {
        if (err.response) this.campaigns_error = err.response.data.error;
      }).finally(() => {
        this.isRefreshing = false;
        this.updateRealTimeUsers();
        this.schedRefresh();
      })
    },
    platformOptions(platform) { return OPTIONS.platform(platform) }
  },
  components: { ClipLoader, CampaignsRow, UnitsTooltip, Multiselect, BIconExclamationCircle }
}
</script>

<style scoped lang="scss">
#panel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 5px 0 5px;

    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      @media (max-width: 420px) {
        width: 100%;
      }
    }

    &:nth-child(7) .field {
      @media (max-width: 420px) {
        margin-bottom: 5px;
      }
    }

    &.source {
      padding-top: 0px;
    }

    .field {
      display: flex;
      height: fit-content;

      label {
        line-height: 40px;
        text-align: right;
        margin: 0;

        @media(max-width: 420px) {
          width: 110px !important;
          text-align: left;
        }

        &.short {
          width: 46px;
        }

        &.medium {
          width: 58px;
        }

        &.long {
          width: 73px;
        }
      }

      input,
      select,
      .multiselect {
        height: 40px;
        width: 220px;
        padding-left: 16px;
        border: 0px;
        border-radius: 10px;
        font-size: 14px;

        @media(max-width: 420px) {
          width: 100%;
        }
      }

      .multiselect.input-field.long {
        width: 265px;

        @media(max-width: 420px) {
          width: 100%;
        }

        &.small {
          font-size: 11px;
        }
      }

      select {
        background-color: #9B9B9B;
        color: white;
      }

      input {
        background-color: #e0e0e0;
      }

      .multiselect {
        padding: 0;
      }

      label+select,
      label+input,
      label+.multiselect {
        margin-left: 15px;

        @media(max-width: 420px) {
          margin-left: auto;
        }
      }

      &.text input {
        width: 200px;

        @media(max-width: 420px) {
          width: 100%;
        }
      }
    }

    .toggle-button {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      font-size: 16px;
      border: 1px solid #E0E0E0;
      text-align: center;
      cursor: pointer;
    }

    h3 {
      text-align: center;
      font-size: 16px;
    }

    .time-zone-buttons {
      width: 80px;
      height: 100px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .toggle-button {
        max-height: 32%;
        line-height: 32px;
        color: #4BCEAD;
        border-color: #4BCEAD;

        &.active {
          background-color: #4BCEAD;
          color: white;
          border-color: white;
        }
      }
    }
  }
}

#panel-controller {
  margin: 15px 0px;

  &>* {
    margin-top: 15px;
  }

  .button {
    border-radius: 8px;
    padding: 0.1em 2.5em;
    margin: 10px 15px 0px 0;
    color: white;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border: 0px;

    &.red {
      background-color: #DB504A;
    }

    &.green {
      background-color: #4BCEAD;
    }

    &.blue {
      background-color: #696FFC;
    }
  }

  .input {
    display: flex;
    margin-left: 16px;
  }

  label {
    line-height: 30px;
    margin: 0px;
  }

  input,
  select,
  .multiselect {
    height: 30px;
    width: 220px;
    padding-left: 16px;
    border: 0px;
    border-radius: 10px;
    font-size: 14px;
    margin-left: 8px;
  }

  select {
    background-color: #9B9B9B;
    color: white;
  }

  input {
    background-color: #e0e0e0;
  }

  .multiselect {
    padding: 0;
  }
}

.site-code {
  font-weight: 800;
  font-size: 18px;
}

.articles {

  &-container-header-th {
    background: #f9f9f9;
  }

  .period {
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

.article-name-title-text {
  white-space: wrap;
  width: 35vw;
  cursor: pointer;
  font-weight: 400;
  display: block;
}

.units {
  white-space: nowrap;
}

.sticky {
  top: 55px;
  z-index: 10;
}

.sticky .th {
  background-color: #dee2e6;
  position: sticky;
  top: 56px;
  z-index: 100;
  border: 0;
}

.sticky tr:first-child {
  border-bottom-style: hidden;
}

.sticky tr:last-child {
  border-top-style: hidden;
}

.thTop {
  background: #dee2e6;
}

.th2 {
  width: 25%;
  position: sticky;
  left: -1px;
  z-index: 1;
}

.thTop:nth-child(5) {
  z-index: 101;
}

td {
  padding: 5px !important;
}

.basic-color {
  background-color: white;
}

.budgets-cell {
  width: 7rem;
}

.chart-td {
  cursor: pointer;
}

.bookmarked {
  background: #fffed4;
}
</style>
<style scoped>
.articles-container {
  overflow: auto;
  height: 1000px;
}

.revenue-btn,
.revenue-btn:hover {
  border: 0;
  font-size: 13px;
  background: none;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  color: #000;
  font-weight: inherit;
}

thead tr:first-of-type {
  font-weight: bold;
  border: 0;
  background-color: #f9f9f9;
}

thead th {
  border-right: 1px solid #d7d8d8;
}

thead tr:nth-child(2) {
  background-color: #dee2e6;
}

thead tr:first-of-type>th:first-of-type,
thead tr:first-of-type>th:nth-of-type(2),
thead tr:first-of-type>th:nth-of-type(3) {
  border: 0;
}

#articles td {
  vertical-align: middle;
}

.input-group input,
.input-group span {
  font-size: 13px;
}

.input-group {
  min-width: 160px;
  flex-wrap: nowrap !important;
}

.input-group .form-control {
  width: 60px;
}

.alert-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
  margin-top: 4rem;
  padding: 1rem;
  text-align: center;
}
</style>
